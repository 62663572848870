import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { API_POST } from "../../services/api.service";
import _ from "underscore";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";

import CardHeader from "components/Card/CardHeader.jsx";
// import Filters from "./Filter";
import Button from "components/CustomButtons/Button.jsx";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CallFilters from "./CallFilters";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
//import { Snackbar } from "@material-ui/core";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import { PauseCircleFilled, PlayCircleFilled } from "@material-ui/icons";
import Loader from "../../components/Loader/Loader";
import InfoBox from "../../components/InfoIcon/InfoIcon";

// import InfoIcon from "@material-ui/icons/Info";
// import Typography from "@material-ui/core/Typography";



const handleURL = () => {
  window.localStorage.setItem("LastURL", window.location.href);
};

const customCell = (value) => {
  let val = "";
  if (value.firstName && value.lastName) {
    val = value.firstName + " " + value.lastName;
  } else if (value.firstName) {
    val = value.firstName;
  } else {
    val = value.phone;
  }
  return (
    <Link onClick={handleURL} to={"/admin/ViewProspect/" + value.pid}>

      {val}
    </Link>
  );
};

function CallRecording(props) {
  const { user } = props;
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [skip, setSkip] = React.useState(0);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 0));
  const [selectedUser, setSelectedUser] = useState(null);
  const [SelectedCampaign, setSelectedCampaign] = useState([]);
  const [open, setOpen] = useState(false);
  const [IsRefresh, setIsRefresh] = useState(false);
  const [Query, setQuery] = useState("");
  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });

  const playref = useRef();


  const audioLib = new Audio();

  const soundPlayer = {
    audio: null,
    muted: false,
    playing: false,
    _ppromis: null,
    data: null,
    pause: function () {
      this.audio.pause();
      if (this.data) {
        document.getElementById(this.data._id).style.display = 'none';
        document.getElementById(this.data._id + "_play").style.display = 'block';
      }
      this.data = null;
    },
    play: function (file) {
      if (this.muted) {
        return false;
      }



      this.audio = audioLib;

      if (this.playing) {
        this.pause();
      }

      this.audio.src = file;
      this._ppromis = this.audio.play();
      this.playing = true;

      if (this._ppromis !== undefined) {
        this._ppromis.then(function () {
          soundPlayer.playing = false;

        });
      }
      if (this.data) {
        document.getElementById(this.data._id).style.display = 'block';
        document.getElementById(this.data._id + "_play").style.display = 'none';
      }
    }
  };

  const pauseRecording = (e, row) => {

    soundPlayer.data = row;
    soundPlayer.pause();



  }

  const playRecording = (e, row) => {

    soundPlayer.data = row;
    let play = soundPlayer.play(row.S3URL);

    console.log(soundPlayer);

  }

  const notPlayFormat = ['amr'];

  const columns = [
    // {
    //   name: "Phone",
    //   selector: "phone",
    // },

    {
      name: "Recordings",
      cell: (row) => (
        <a
          href={row.S3URL}
          target="_blank"
        >
          <Button color="info">Download</Button>

          {row.fileSize > 0 && !row.dataSource && (row.fileSize && (row.fileSize / (1024 * 1024)).toFixed(2) + 'Mb')
          }


        </a>
      ),
    },
    {
      name: "Audio",
      width: "100px",
      cell: (row) => (
        <>

          {

            row.S3URL && row.S3URL.includes('.amr') == false && (
              <>
                <div id={row._id + "_play"}>
                  <PlayCircleFilled style={{ color: "blue" }} onClick={(e) => { playRecording(e, row) }} />

                </div>
                <div style={{ display: 'none' }} id={row._id}>
                  <PauseCircleFilled onClick={(e) => { pauseRecording(e, row) }} />
                </div>
              </>
            )
          }
        </>
      ),
    },
    {
      name: "Call Time",
      cell: (row) => (
        <span>
            {row.endDate &&
              new Date(row.endDate).toLocaleString()}
          </span>
        
      ),
    },

    {
      name: "User",
      selector: row => row.user,
    },
    {
      name: "FileName",
      selector: row => row.filename,
      width: '300px'
    },

    // {
    //   name: "Phone",
    //   cell: (row) => (
    //     <span>
    //       {row.phone  && row.phone !="+" &&
    //        row.phone}
    //     </span>
    //   ),
    // },
    // {
    //   name: "Type",
    //   selector: "type",
    // },


    /* {
       name: "Audio",
       cell: (row) => (
         <audio controls>
             <source src={row.S3URL} type="audio/mp3" />
           </audio>
       ),
     },*/


    // {
    //   name: "Download Recording",
    //   cell: (row) => (
    //     (row.filename.indexOf('.mp3') > -1) ?
    //       <audio controls>            
    //         <source src={row.S3URL} type="audio/mp3" />
    //       </audio>
    //       :
    //       <a
    //         href={row.S3URL}
    //         target="_blank"
    //       >
    //         <PlayCircleOutlineIcon />
    //       </a>
    //   ),
    // },
    // {
    //   name: "Play Recording",
    //   cell: (row) => <span onClick={(e) => playRecording(e, row)}>Play</span>,
    // },
  ];



  const handleChangePage = (newPage) => {
    setPage(newPage);
    setSkip((newPage - 1) * countPerPage);
  };

  const pageLoadquery = async () => {
    debugger;
    let query;
    const urlParam = new URLSearchParams(window.location.search);
    let search = urlParam.get("Search");

    if (!search) {
      let data = {
        SelectedCampaign: SelectedCampaign,
        selectedUser: selectedUser,
      };

      query = await handlequery(data, query);
      query = {
        ...query,
        // startDate: {
        //   $gte: new Date(startDate),
        //   $lt: new Date(endDate),
        // },
      };
    } else {
      if (search) {
        search = JSON.parse(atob(search));
      }

      query = { ...search, admin: user && user.admin };
      if (user && ["field_exc", "agent"].indexOf(user.role) > -1) {
        query = { ...query, user: user && user.username };
      }
    }

    return query;
  };

  const handlequery = async (data, query) => {


    if (data.mobileNo && data.mobileNo.length > 0) {

      query = { ...query, filename: { '$regex': data.mobileNo } };

    }

    // if (data.selectedUser && data.selectedUser.length > 0) {
    //   query = { ...query, user: { ["$in"]: data.selectedUser } };
    // }

    if (user && ["field_exc", "agent"].indexOf(user.role) > -1) {
      query = { ...query, user: user && user.username };
    }
    // if (data.selectedDisposition && data.selectedDisposition.length > 0) {
    //   query = { ...query, disposition: { ["$in"]: data.selectedDisposition } };
    // }


    if (data && data.startDate && data.endDate) {
      query = {
        ...query,
        endDate: {
          $gte: new Date(data.startDate),
          $lt: new Date(data.endDate),
        },
      };
    }

    if (data.selectedUser && data.selectedUser.length > 0) {

      query = { ...query, user: { '$in': data.selectedUser } };

    }


    query = { ...query, admin: user && user.admin };
    return query;
  };

  const loadCallRecordData = async () => {
    debugger;

    if (!user || (user && !user.admin)) {
      return;
    }

    let con = await pageLoadquery();

    let body = {
      limit: countPerPage,
      skip: skip,
      sort: { endDate: -1 },
    };
    setQuery(con);


    if (user && ["manager"].indexOf(user.role) > -1) {
      if (con.user == '') {
        con = { ...con, user: { $in: user.members } };
      }
    }


    con = { ...con };

    let res = await API_POST("action/getPaginationDataActivity", {
      root: "CallRecording",
      querydata: con,
      body: body,
    });
    debugger;
    setData(res.data || []);
  };

  // const loadCallRecordData = async () => {
  //   if (user && user.admin) {
  //     //let con = await pageLoadquery();
  //       let con = { admin: user && user.admin };

  //       if (fid) {
  //         con = { ...con, fid };
  //       } else {
  //         con = { ...con, InSystem: "API" };
  //       }

  //       if (SelectedStatus && SelectedStatus.updateStatus) {
  //           con = { ...con, updateStatus:  SelectedStatus.updateStatus  };
  //         }

  //     let res = await API_GET(
  //       `action/getProspectHistory?page=${page}&per_page=${countPerPage}&delay=1`,
  //       null,
  //       {
  //         query: con,
  //       }
  //     );
  //     debugger;
  //     setData(res.data);
  //   }
  // };

  useEffect(() => {
    loadCallRecordData();
  }, [page]);

  const handleUrl = async (data) => {
    debugger;
    let query;
    query = await handlequery(data, query);
    let search = btoa(JSON.stringify(query));
    const { history } = props;

    history.push({
      pathname: `/admin/CustActivity`,
      search: `tab=2&Search=${search}`,
    });
    //loadCallRecordData();
  };
  const handleExportExcel = async () => {
    let downloadFile = await API_POST(`action/ExportExcel`, {
      querydata: Query,
      body: { admin: user.admin, user: user.username, page: "CallRecording" },
    });
    if (downloadFile.status == 200) {
      setNotify({
        open: true,
        message: "File is in process,Please check in system log section.",
        color: "success",
      });
    }
  };

  const filterData = (data) => {
    debugger;
    setStartDate(data.startDate);
    setEndDate(data.endDate);
    setSelectedUser(data.selectedUser);
    setSelectedCampaign(data.SelectedCampaign);
    setIsRefresh(true);
    // setTimeout(function(){loadCallRecordData()}, 500)
    setOpen(false);
    handleUrl(data);
  };
  useEffect(() => {
    if (IsRefresh) {
      loadCallRecordData();
      setIsRefresh(false);
    }
  }, [IsRefresh]);

  return (
    <>
      <div style={{ float: 'right' }}>

      </div>


      <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => setNotify({ open: false })}
        close
      />
      {/* <Card>
        <CardHeader color="transparent"> */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h3>
            Call Recording
            <span style={{ float: "right", fontSize: "15px" }}>
              <Button
                size="sm"
                title="Filter"
                color="info"
                onClick={() => {
                  setOpen(true);
                }}

              >
                <i className="fa fa-filter" /> &nbsp; Filter
              </Button>
              {["manager", "owner"].indexOf(user && user.role) > -1 && (
                <Button
                  size="sm"
                  color="transparent"
                  justIcon
                  title="Export To Excel"
                  onClick={() => {
                    handleExportExcel();
                  }}
                >
                  <GetAppOutlinedIcon />
                </Button>
              )}
            </span>
            <GridItem style={{ float: "right", marginTop: "10px" }}>
              <InfoBox name={'Activity.CallRecording'} />
            </GridItem>
          </h3>
        </GridItem>

        {open && (
          <CallFilters
            Recording_IsShowField={true}
            open={open}
            handleClose={() => {
              setOpen(false);
            }}
            filterData={filterData}
            user={user}
          />
        )}
      </GridContainer>
      {/* </CardHeader> */}

      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style={{ minHeight: '200px', padding: '0 20px !important' }}>
          {data && !data.activityData && <div> <Loader></Loader></div>}
          {data && data.activityData && <DataTable
            columns={columns}
            data={data.activityData || []}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={data.total}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            onChangePage={handleChangePage}
          />}
        </GridItem>
      </GridContainer>
      {/* </Card> */}
    </>
  );
}

export default CallRecording;
