import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Card from "../../../../../components/Card/Card.jsx";
import CardHeader from "../../../../../components/Card/CardHeader.jsx";
import CardBody from "../../../../../components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { API_POST,API_GET } from "../../../../../services/api.service";
import Snackbar from "../../../../../components/Snackbar/Snackbar.jsx";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  formGroup: {
    marginBottom: theme.spacing(3)
  },
  formControl: {
    margin: theme.spacing(2, 0)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3)
  },
  switchGroup: {
    marginTop: theme.spacing(2)
  }
}));

class NotificationSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            user: null,
            userInfo: null,
            // AllMembersByUserId: null,
            notify: {
                open: false,
                message: "",
                color: "success",
            },
            avbUserCount: 0,
            openchangemanager: false,
            openchangerole: false,
            openchangepassword: false,
            selectedMember: null,
            openchangewebphone: false,
            newPassword: null,
            IsWebPhone: false,
            empId: null,
            agentMobile: null,
            callType: null,
            setting:null,
            activeTab:null,
            profile:null
        };
        this.addMember = this.addMember.bind(this);
        this.userRoles = ["manager", "agent", "field_exc"];

    }

    loadUserInfo = async (username) => {
        debugger;
        let userInfo = await API_GET(
            `register/GetProfileData?username=${username}`
        );
        debugger;
        if (userInfo.success) {
            this.setState({ profile: userInfo.data });

        }
    };

    async addMember(e) {
        e.preventDefault();

        const fields = ["name", "email", "admin_id"];
        const formElements = e.target.elements;
        const formValues = fields
            .map((field) => ({
                [field]: formElements.namedItem(field).value.trim(),
            }))
            .reduce((current, next) => ({ ...current, ...next }));

        let registerRequest;
        try {
            registerRequest = await API_POST("register/addMember", formValues);
            console.log(registerRequest);
            debugger;

            if (registerRequest && registerRequest.success) {
                this.setState({
                    notify: {
                        open: true,
                        message: registerRequest.messages.success,
                        color: "success",
                    },
                });
                this.loadUserInfo(this.state.user.username);
                this.GetAllMembersByUserId(this.state.user.id);
                //debugger;
                document.getElementById("frmAddMember").reset();
            } else {
                this.setState({
                    notify: {
                        open: true,
                        message: registerRequest.messages,
                        color: "danger",
                    },
                });
            }
        } catch (error) {
            console.log(error);
            //registerRequest = response;
        }

        debugger;
    }

    async promoteMember(e, element) {
        e.preventDefault();
        let member = this.state.selectedMember;
        if (!window.confirm("Are you sure! you wanna promote " + member.username)) {
            return;
        }
        try {
            let registerRequest = await API_POST("register/changeRole", {
                user_id: member.user_id,
                role: element,
            });
            console.log(registerRequest);
            if (registerRequest && registerRequest.success) {
                this.setState({
                    notify: {
                        open: true,
                        message: "User role changed successfully.",
                        color: "success",
                    },
                    openchangerole: false,
                });
                //this.loadUserInfo(this.state.user.id);
                this.GetAllMembersByUserId(this.state.user.id);
            }
        } catch ({ response }) {
            //registerRequest = response;
        }
    }

    async componentDidMount() {
        let getSessionRequest;
        getSessionRequest = await API_GET(`get-session`);
        //Page Authorization
        if (getSessionRequest && getSessionRequest.success) {
            this.setState({ user: getSessionRequest.userInfo });

            this.loadUserInfo(getSessionRequest.userInfo.username);
            this.GetAllMembersByUserId(getSessionRequest.userInfo.id);

            this.getSetting();

        }

    }

    async loadUserInfo(userid) {
        let userInfo = await API_POST(`register/userinfo?id=${userid}`);
        if (userInfo.success) {
            // this.setState({
            //   notify: {
            //     open: true,
            //     message: 'Loaded',
            //     color: 'success'
            //   }
            // });
            this.setState({ userInfo: userInfo.data });
        } else {
            this.setState({ userInfo: null });
        }
    }

    async GetAllMembersByUserId(userid) {

        let userInfo = await API_GET(`register/GetAllMembersByUserId?id=${userid}`);
        if (userInfo.success) {
            debugger;
            this.setState({ userInfo: userInfo.data });
            if (userInfo.data.avbUserCount) {
                this.setState({
                    avbUserCount: userInfo.data.avbUserCount,
                });
            }
        } else {
            this.setState({ userInfo: null });
        }
    }

    async handleListItemClick(e, manager) {
        try {
            let registerRequest = await API_POST("register/changeManager", {
                user_id: this.state.selectedMember.user_id,
                reporter_id: manager.user_id,
            });

            console.log(registerRequest);
            if (registerRequest && registerRequest.success) {
                this.setState({
                    notify: {
                        open: true,
                        message: "Manager changed.",
                        color: "success",
                    },
                });
                //this.loadUserInfo(this.state.user.id);
                this.GetAllMembersByUserId(this.state.user.id);
            }
        } catch ({ response }) {
            console.log(response);
        }
        this.handleClose();
    }

    async handleClose(e) {
        this.setState({
            openchangemanager: false,
        });
    }

    async changeManager(e, element) {
        e.preventDefault();
        this.setState({
            openchangemanager: true,
            selectedMember: element,
        });
    }
    async changeRole(e, element) {
        e.preventDefault();
        this.setState({
            openchangerole: true,
            selectedMember: element,
        });
    }
    async changePassword(e, element) {
        e.preventDefault();
        this.setState({
            openchangepassword: true,
            selectedMember: element,
        });
    }

    async changeWebPhone(e, element) {
        e.preventDefault();

        this.setState({
            openchangewebphone: true,
            selectedMember: element,
            agentMobile: element.agentMobile,
            IsWebPhone: element.IsWebPhone,
            callType: element.callType,
            empId: element.empId
        });
    }


    async sentEmail(e, element) {
        e.preventDefault();
        try {
            let request = await API_POST(
                `email/sendCode?user_id=${element.to_user_id}`
            );
            console.log(request);
            if (request && request.success) {
                this.setState({
                    notify: {
                        open: true,
                        message: request.messages.success,
                        color: "success",
                    },
                });
                //this.loadUserInfo(this.state.user.id);
                this.GetAllMembersByUserId(this.state.user.id);
            }
        } catch ({ response }) {
            console.log(response);
        }
    }

    saveWebPhone = async (e) => {
        this.saveWebPhoneInDb();
    };

    saveWebPhoneInDb = async () => {
        let webPhoneRequest;

        webPhoneRequest = await API_POST("register/updateWebPhone", {
            IsWebPhone: this.state.IsWebPhone,
            empId: this.state.empId,
            agentMobile: this.state.agentMobile,
            callType: this.state.callType,
            user: this.state.selectedMember.id,
        });


        if (webPhoneRequest && webPhoneRequest.data.update) {
            this.setState({
                openchangewebphone: false,
                notify: {
                    open: true,
                    message: "Data Updated",
                    color: "success",
                },
            });
            this.GetAllMembersByUserId(this.state.user.id);
        } else if (webPhoneRequest && webPhoneRequest.data.error) {
            this.setState({
                notify: {
                    open: true,
                    message: webPhoneRequest.data.msg,
                    color: "danger",
                },
            });

        } else {
            this.setState({
                notify: {
                    open: true,
                    message: "Something went wrong",
                    color: "danger",
                },
            });
        }

    };

    savePassword = async (e) => {
        this.savePasswordInDB(this.state.newPassword);
    };

    savePasswordInDB = async (password) => {
        let registerRequest;

        registerRequest = await API_POST("register/ResetPassword", {
            password,
            user: this.state.selectedMember.username,
        });

        if (registerRequest && registerRequest.data.update) {
            this.setState({
                openchangepassword: false,
                notify: {
                    open: true,
                    message: "Password Changed",
                    color: "success",
                },
            });
        } else {
            this.setState({
                openchangepassword: false,
                notify: {
                    open: true,
                    message: "Something went wrong",
                    color: "danger",
                },
            });
        }
    };

    async sendApprove(e, element) {
        e.preventDefault();
        try {
            let request = await API_POST(`email/verify?code=${element.code}`);
            console.log(request);
            if (request && request.success) {
                this.setState({
                    notify: {
                        open: true,
                        message: request.messages.success,
                        color: "success",
                    },
                });
                //this.loadUserInfo(this.state.user.id);
                this.GetAllMembersByUserId(this.state.user.id);
            } else {
                this.setState({
                    notify: {
                        open: true,
                        message: request.messages.error,
                        color: "danger",
                    },
                });
            }
        } catch ({ response }) {
            console.log(response);
        }
    }

    async changeUserType(e, element, Type) {
        debugger;
        let IsRemove = true;
        // if (Type == 0) {
        //   IsRemove = await this.ChkAssignedLead(element.username);
        // }
        if (IsRemove) {

            let registerRequest = await API_POST("register/changeUserState", {
                Type: Type,
                id: element.user_id,
                username: element.username,
            });
            debugger;
            if (registerRequest && registerRequest.data.update) {

                this.setState({
                    notify: {
                        open: true,
                        message: "Save Successfully",
                        color: "success",
                    },
                });
                this.GetAllMembersByUserId(this.state.user.id);
            }
        } else {
            this.setState({
                notify: {
                    open: true,
                    message: "Please change the assignment before remove member.",
                    color: "danger",
                },
            });
        }
    }
    async ChkAssignedLead(username) {
        let Request;
        Request = await API_POST("action/getCount", {
            root: "prospects",
            con: {
                // cid,
                assignTo: username,
            },
        });
        if (Request && Request.status == 200 && Request.data > 0) {
            return false;
        } else {
            return true;
        }
    }



    async getSetting(){


        let  Request = await API_POST("action/getdata", {
            root: "Configuration",
            con: {
                context:'notification',
                admin: this.state.user && this.state.user.admin,
                $and:[{ $or:[
                    {name:"smsNotificationEnable"},
                    {name:"emailNotificationEnable"},
                    {name:"whatsappNotificationEnable"}
                ] }],
            },
        });



        let settings={};
        for(let i=0;i<Request.data.length;i++){

            if(Request.data[i].name === true){
                continue;
            }

            settings[Request.data[i].name]=Request.data[i];
        }


        console.log(settings);


        if(!settings['smsNotificationEnable'] || settings['smsNotificationEnable'] &&  settings['smsNotificationEnable'].phone==''){
        //    settings['smsNotificationEnable']={phone:this.state.profile && this.state.profile.phone};
        }

        if(!settings['emailNotificationEnable'] || settings['emailNotificationEnable'] &&  settings['emailNotificationEnable'].email==''){
          //  settings['emailNotificationEnable']={email:this.state.profile && this.state.profile.email};
        }

        if(!Request.data.length){
            if(!settings['smsNotificationEnable']){
                settings['smsNotificationEnable'] = { value:true , phone:this.state.user.phone };
            }

            if(!settings['emailNotificationEnable']){
                settings['emailNotificationEnable'] = { value:true , email:this.state.user.email };
            }
        }


        this.setState( { setting: settings} );

        return true;

    }


    save = async () =>{

        let settings=this.state.setting;


        console.log(settings);

        for(let value in settings){

            delete settings[value]._id;

          await   API_POST("action/upsertdddd", {
                root: "Configuration",
                querydata:{
                    name:settings[value].name,
                    context:'notification',
                    admin: this.state.user && this.state.user.admin,
                },
                body: {
                    // cid,
                    ...settings[value],
                    context:'notification',
                    userId:this.state.user && this.state.user.id,
                    admin: this.state.user && this.state.user.admin,
                },
            });

            await     API_POST("action/updateMany", {
                root: "Configuration",
                querydata:{
                    name:settings[value].name,
                    context:'notification',
                    admin: this.state.user && this.state.user.admin,
                },
                body: {
                    // cid,
                    ...settings[value],
                    context:'notification',
                    userId:this.state.user && this.state.user.id,
                    admin: this.state.user && this.state.user.admin,
                },
            });



        }




    }

    async setSettings(e,value){


        let settings=this.state.setting;


        console.log("old",settings);

        settings[value.name]={...settings[value.name],...value};



        console.log(settings);
        console.log(value);

        this.setState( { setting: settings} );

/*
        await API_POST("action/upsert", {
            root: "Configuration",
            querydata:{
                name:value.name,
                context:'notification',
                admin: this.state.user && this.state.user.admin,
            },
            body: {
                // cid,
                ...value,
                context:'notification',
                userId:this.state.user && this.state.user.id,
                admin: this.state.user && this.state.user.admin,
            },
        });*/

      //  await this.getSetting();
        return true;

    }



    render() {
        const { classes, name, email } = this.props;
        const { errors, user, userInfo,setting,profile } = this.state;

        // if (!userInfo) {
        //   return null;
        // }

        var managers = [];
        var managerslist = [];
        var tableData = [];



        let Invitees = null;


        return (
            <div>
                <Snackbar
                    place="tr"
                    color={this.state.notify.color}
                    message={this.state.notify.message}
                    open={this.state.notify.open}
                    closeNotification={() =>
                        this.setState({
                            notify: {
                                open: false,
                            },
                        })
                    }
                    close
                />
                <GridContainer>

                    {
                        user && user.role=='owner' && (

                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader color="transparent">
                                        <h3>Notification</h3>
                                    </CardHeader>
                                    <CardBody>



                                        <GridContainer>
                                            <GridItem xs={12} sm={6} md={6}>
                                                <div>

                                                    <input name="activeTab" type="radio" onClick={()=>this.setState({ activeTab: 'sms' }) } /> Sms Service
                                                    <input type="radio" name="activeTab" onClick={()=>this.setState({ activeTab: 'whatsapp' }) } /> WhatsApp Service
                                                    <input type="radio" name="activeTab" onClick={()=>this.setState({ activeTab: 'email' }) } /> Email Service

                                                </div>
                                                <GridContainer>

                                                    {this.state.activeTab=='sms' && (
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <h3>

                                                                <input type={"checkbox"}
                                                                       checked={setting &&  setting['smsNotificationEnable'] &&  setting['smsNotificationEnable'].value}
                                                                       onChange={ (e) => {
                                                                           this.setSettings(e,{name:'smsNotificationEnable',value:e.target.checked});
                                                                       } } /> Enable Sms</h3>

                                                            <GridItem xs={12} sm={12} md={12}>

                                                                <CustomInput
                                                                    labelText="Phone Number"
                                                                    id="name"
                                                                    error={errors.name}
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                    }}
                                                                    inputProps={{
                                                                        type:"number",
                                                                        required: true,
                                                                        value: setting && setting['smsNotificationEnable'] && setting['smsNotificationEnable'].phone,
                                                                        defaultValue:setting && setting['smsNotificationEnable'] && setting['smsNotificationEnable'].phone ,
                                                                        name: "smsNotificationValue",
                                                                        onChange: (e) => {
                                                                            this.setSettings(e,{name:'smsNotificationEnable',phone:e.target.value});
                                                                        },
                                                                    }}
                                                                />


                                                            </GridItem>




                                                        </GridItem>
                                                    )}

                                                    {this.state.activeTab=='whatsapp' && (
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <h3>

                                                                <input type={"checkbox"}
                                                                       checked={setting && setting['whatsappNotificationEnable'] && setting['whatsappNotificationEnable'].value}
                                                                       onChange={ (e) => {
                                                                           this.setSettings(e,{name:'whatsappNotificationEnable',value:e.target.checked});
                                                                       } } />

                                                               Enable WhatsApp</h3>

                                                            <GridItem xs={12} sm={12} md={12}>

                                                                <CustomInput
                                                                    labelText="WhatsApp Phone Number"
                                                                    id="name"
                                                                    error={errors.name}
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                    }}
                                                                    inputProps={{

                                                                        value: setting && setting['whatsappNotificationEnable'] && setting['whatsappNotificationEnable'].phone,
                                                                        name: "whatsappNotificationValue",
                                                                        onChange: (e) => {
                                                                            this.setSettings(e,{name:'whatsappNotificationEnable',phone:e.target.value});
                                                                        },
                                                                    }}
                                                                />





                                                            </GridItem>
                                                        </GridItem>
                                                    )}

                                                    {this.state.activeTab=='email' && (
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <h3>
                                                                <input type={"checkbox"}
                                                                       checked={setting && setting['emailNotificationEnable'] &&  setting['emailNotificationEnable'].value}
                                                                       onChange={ (e) => {
                                                                           this.setSettings(e,{name:'emailNotificationEnable',value:e.target.checked});
                                                                       } } />

                                                               Enable Email </h3>

                                                            <GridItem xs={12} sm={12} md={12}>

                                                                <CustomInput
                                                                    labelText="Email Address"
                                                                    id="email"
                                                                    error={errors.name}
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                    }}
                                                                    inputProps={{
                                                                        type:"email",
                                                                        required: true,
                                                                        value: setting && setting['emailNotificationEnable'] && setting['emailNotificationEnable'].email ,
                                                                        name: "emailNotificationValue",
                                                                        onChange: (e) => {
                                                                            this.setSettings(e,{name:'emailNotificationEnable',email:e.target.value});
                                                                        },
                                                                    }}
                                                                />



                                                            </GridItem>
                                                        </GridItem>
                                                    )}

                                                </GridContainer>
                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={6}>
                                                <h3>Notification Details</h3>

                                                {
                                                    setting && setting['emailNotificationEnable'] && setting['emailNotificationEnable'].value==true && (
                                                        <div>
                                                            <p> Email Service :<b> { setting && setting['emailNotificationEnable'] && setting['emailNotificationEnable'].email } </b></p>
                                                        </div>
                                                    )

                                                }

                                                {
                                                    setting && setting['smsNotificationEnable'] && setting['smsNotificationEnable'].value==true && (
                                                        <div>
                                                            <p> Sms Service :<b> { setting && setting['smsNotificationEnable'] && setting['smsNotificationEnable'].phone } </b></p>
                                                        </div>
                                                    )

                                                }



                                                {
                                                    setting && setting['whatsappNotificationEnable'] && setting['whatsappNotificationEnable'].value==true && (
                                                        <div>
                                                            <p> WhatsApp Service :<b> { setting && setting['whatsappNotificationEnable'] && setting['whatsappNotificationEnable'].phone } </b></p>
                                                        </div>
                                                    )

                                                }
                                            </GridItem>
                                        </GridContainer>


                                        <Button
                                            color="primary"
                                            onClick={this.save}
                                        >Save
                                        </Button>

                                    </CardBody>
                                </Card>
                            </GridItem>


                        )

                    }

                </GridContainer>
            </div>
        );
    }
}

export default NotificationSettings;
