import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ErrorBoundary from '../../hoc/ErrorBoundary/ErrorBoundary';
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";


// Module Tabs
import SettingsTab from './modules/Settings/SettingsTab';
import TemplatesTab from './modules/Templates/TemplatesTab';
import ViewTags from "../Forms/ViewTags";
import Disposition from "../Forms/Disposition";
import Tags from './Tags';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  title: {
    marginBottom: theme.spacing(3)
  }
}));

const SettingsPage = (props) => {
  const { user, history, match } = props;
  const classes = useStyles();
  const [ activeTab, setActiveTab ] = useState(0);
  const [campaignDetails, setCampaignDetails] = useState(null);
  const [isMobile] = useState(window.innerWidth <= 760);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    if (tab) {
      setActiveTab(parseInt(tab));
    }
  }, []);

  const handleTabChange = (event, value) => {
    setActiveTab(value);
    history.push('?tab=' + value);
  };

  const tabs = [
    {
      tabName: "Disposition",
      tabContent: (
        <ErrorBoundary name="Disposition">
          <Disposition user={user} />
        </ErrorBoundary>
      )
    },
    {
      tabName: "Settings",
      tabContent: (
        <ErrorBoundary name="Settings">
          <SettingsTab user={user} />
        </ErrorBoundary>
      )
    },
    {
      tabName: "Templates",
      tabContent: (
        <ErrorBoundary name="Templates">
          <TemplatesTab user={user} history={history} />
        </ErrorBoundary>
      )
    },
    {
      tabName: "Tags",
      tabContent: (
        <ErrorBoundary name="Tags">
          <Tags {...props} />
        </ErrorBoundary>
      )
    }
  ];

  return (
      <div className={classes.root}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {campaignDetails && (
              <Typography variant="h5" component="h2" className={classes.title}>
                {campaignDetails.name}
              </Typography>
            )}
            <CustomTabs
              title=""
              headerColor="info"
              tabs={tabs}
              value={activeTab}
              onChange={handleTabChange}
            />
          </GridItem>
        </GridContainer>
      </div>
  );
};

SettingsPage.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default SettingsPage;
