import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Link } from "react-router-dom";
import { API_POST } from "../../services/api.service";
import _ from "underscore";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Filters from "./Filters";
import Button from "components/CustomButtons/Button.jsx";
import ProspectCampaign from "../Prospects/ProspectCampaign";
import CallBackChange from "./CallBackChange";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { Snackbar } from "@material-ui/core";
import Box from "../Dashboard/Box";
// import InfoIcon from "@material-ui/icons/Info";
// import Typography from "@material-ui/core/Typography";
import { array } from "prop-types";
import InfoBox from "../../components/InfoIcon/InfoIcon";
import { getManagerTeam } from "../common/helper";


const columns = [
  {
    name: "Prospect",
    cell: (row) => <span>{customCell(row)}</span>,
  },
  {
    name: "Disposition",
    cell: (row) => (
      <span>
        {_.pluck(row.dispositionArray, "disposition_value").join(" > ")}
      </span>
    ),
  },
  {
    name: "Scheduled Time",
    cell: (row) => (
      <span>
        {row.callback && new Date(row.callback).toLocaleString()}
      </span>
    ),
  },
  {
    name: "Campaign",
    cell: (row) => (
      <span>
        {row.CampainData && row.CampainData.length > 0
          ? row.CampainData[0].name
          : "-"}
      </span>
    ),
  },
  {
    name: "Remarks",
    cell: (row) => <span>{row.remarks}</span>,
  },
  {
    name: "Last Called Time",
    cell: (row) => (
      <span>
        {row.LastCalled &&
          new Date(row.LastCalled).toLocaleString()}
      </span>
    ),
  },
  {
    name: "Last Caller",
    cell: (row) => <span style={{wordWrap: "break-word", width: "100%"}}>{row.LastCaller}</span>,
  },
  {
    name: "Scheduled by",
    cell: (row) => <span style={{wordWrap: "break-word", width: "100%"}}>{row.assignTo}</span>,
  },
];
const handleURL = () => {
  window.localStorage.setItem("LastURL", window.location.href);
};

const customCell = (value) => {
  let val = "";
  if (value.firstName && value.lastName) {
    val = value.firstName + " " + value.lastName;
  } else if (value.firstName && value.firstName != ' ') {
    val = value.firstName;
  } else {
    val = value.phone;
  }
  return (
    <Link onClick={handleURL} to={"/admin/ViewProspect/" + value._id + '/1'}>
      {val}
    </Link>
  );
};

const checkprospect = [];

function Followups(props) {
  const { user } = props;
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [skip, setSkip] = React.useState(0);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 0));
  const [selectedUser, setSelectedUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [IsCampainChange, setIsCampainChange] = useState(false);
  const [IsCallBackChange, setIsCallBackChange] = useState(false);

  const [IsRefresh, setIsRefresh] = useState(false);
  const [Campaigns, setCampaigns] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [AllProspects, setAllProspects] = useState([]);
  const [SelectedCampaign, setSelectedCampaign] = useState([]);
  const [Query, setQuery] = useState("");
  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });
  const [TotalQuery, setTotalQuery] = useState(null);

  const [overDueQuery, setOverDueQuery] = useState(null);
  // const [overDueQuery, setOverDueQuery] = useState();

  const [futurefollowup, setFuturefollowup] = useState(null);

  //const [futurefollowup, setFuturefollowup] = useState();

  const [mobileNo, setMobileNo] = useState(null);
  const [overdue, setOverdue] = useState(null);


  const handleChangePage = (newPage) => {
    setPage(newPage);
    setSkip((newPage - 1) * countPerPage);
  };

  const pageLoadquery = async () => {
    let query;
    const urlParam = new URLSearchParams(window.location.search);
    let search = urlParam.get("Search");

    if (!search) {
      let data = {
        SelectedCampaign: SelectedCampaign,
        selectedUser: selectedUser,
      };

      query = await handlequery(data, query);
      query = {
        ...query,
        open: true,
        //callback: { $ne: null },
        callback: {
          $gte: new Date(startDate),
          $lt: new Date(endDate),
        },
      };
    } else {
      query = { ...JSON.parse(atob(search)), admin: user && user.admin };
    }

    return query;
  };



  const tempcheckprospect = [];
  const loadFollowUpData = async () => {
    //check state on page refresh
    let con = await pageLoadquery();

    let body = {
      limit: countPerPage,
      skip: skip,
      sort: { callback: 1 },
    };
    setQuery(con);

    let prospect_res = await API_POST("action/getdata", {
      root: "prospect_fields",
      con: { admin: con.admin, IsSchedule: { $exists: true } },
    });




    if (prospect_res.data.length > 0) {

      let prospectFileds = prospect_res.data;


      for (let index = 0; index < prospectFileds.length; index++) {

        let checkexists = columns.find(v => v.name == prospectFileds[index].display_Name);

        if (!checkexists && prospectFileds[index].IsSchedule == true) {

          columns.push({
            name: prospectFileds[index].display_Name,
            selector: prospectFileds[index].field_name,
          });
        }

      }


      //    checkprospect.push(tempcheckprospect)

    }

    if (user.role == 'manager') {
      let managerTeam = await getManagerTeam(user);
      console.log("teams manager", managerTeam)
      const managetCallers = { assignTo: { $in: managerTeam.map(v => v.username) } };

      if (con['$and']) {
        // If $and already exists, push the new condition inside
        con['$and'].push(managetCallers);
      } else {
        // If $and doesn't exist, create it with the new condition
        con['$and'] = [managetCallers];
      }
    }




    let res = await API_POST("action/getPaginationDataActivity", {
      root: "prospects",
      querydata: con,
      body: body,
    });

    setData(res.data || []);
    setCampaigns((res.data && res.data.Campaigns) || []);

    let cidGroup = [];
    for (let i = 0; i < res.data.Campaigns.length; i++) {
      cidGroup.push(res.data.Campaigns[i]._id);
    }

    let overdue = await API_POST("action/startCall", {
      "username": user && user.username,
      "type": "overdueOld",
      cidGroup: cidGroup,
      cid: cidGroup[0]
    });

    if (overdue.data.data) {
      setOverdue(overdue.data.data._id);
    }

  };

  const loadAggData = async () => {
    debugger;
    let totalQuery = {
      $and: [
        {
          IsActive: true,
        },
        {
          open: true
        }
      ],
      callback: { $ne: null },
    }
    let _overDueQuery = {
      $and: [
        {
          IsActive: true,
        },
        {
          open: true
        },
      ],
      callback: {
        $lte: new Date(),
      },
    }
    let _futurefollowup = {
      $and: [
        {
          IsActive: true
        },
        {
          open: true
        },
      ],
      callback: {
        $gte: new Date(),
      },
    }


    if (["field_exc", "agent"].indexOf(user && user.role) > -1) {
      totalQuery = { ...totalQuery, assignTo: user.username }
      _overDueQuery = { ..._overDueQuery, assignTo: user.username }
      _futurefollowup = { ..._futurefollowup, assignTo: user.username }
    }
    setTotalQuery(totalQuery);
    setOverDueQuery(_overDueQuery);
    setFuturefollowup(_futurefollowup);
  }

  const handlequery = async (data, query) => {
    debugger;
    query = {};
    // query = {
    //   $and: [
    //     { admin: user && user.admin },
    //     {
    //       $or: [{ IsActive: { $exists: false } }, { IsActive: true }],
    //     },
    //   ],
    // };

    if (data.SelectedCampaign && data.SelectedCampaign.length > 0) {
      query = { ...query, cid: { ["$in"]: data.SelectedCampaign } };
    }

    if (data.selectedUser && data.selectedUser.length > 0) {
      query = { ...query, assignTo: { ["$in"]: data.selectedUser } };
    }
    if (data.selectedDisposition && data.selectedDisposition.length > 0) {
      query = { ...query, disposition: { ["$in"]: data.selectedDisposition } };
    }

    if (user && ["field_exc", "agent"].indexOf(user.role) > -1) {
      query = { ...query, assignTo: user && user.username };
    }


    if (data.mobileNo && data.mobileNo.length > 0) {
      query = { ...query, phone: data.mobileNo };

    }


    if (data.startDate && data.endDate) {
      query = {
        ...query,
        callback: {
          $gte: new Date(data.startDate),
          $lt: new Date(data.endDate),
        },
      };
    }
    query = { ...query, admin: user && user.admin, IsActive: true };
    return query;
  };

  const handleUrl = async (data) => {
    let query;
    query = await handlequery(data, query);
    let search = btoa(JSON.stringify(query));
    const { history } = props;

    history.push({
      pathname: `/admin/Schedule`,
      search: `Search=${search}`,
    });
    loadFollowUpData();
  };

  useEffect(() => {
    loadFollowUpData();
  }, [page]);

  useEffect(() => {
    if (user) {
      loadAggData();
    }
  }, [user, user && user.admin]);


  const filterData = (data) => {
    setStartDate(data.startDate);
    setEndDate(data.endDate);
    setSelectedUser(data.selectedUser);
    setSelectedCampaign(data.SelectedCampaign);
    setIsRefresh(true);
    setOpen(false);
    setMobileNo(data.mobileNo);

    handleUrl(data);
  };

  // useEffect(() => {
  //   if (IsRefresh) {
  //     loadFollowUpData();
  //     setIsRefresh(false);
  //   }
  // }, [IsRefresh]);

  const RemoveAllChecks = (flag) => {
    var checkboxes = document.querySelectorAll('input[type="checkbox"]');
    for (var i = 0; i < checkboxes.length; i++) checkboxes[i].checked = flag;
  };

  const handleCheckBox = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };
  const handleExportExcel = async (e) => {
    let downloadFile = await API_POST(`action/ExportExcel`, {
      querydata: Query,
      body: { admin: user.admin, user: user.username, page: "FollowUp" },
    });
    if (downloadFile.status == 200) {
      setNotify({
        open: true,
        message: "File is in process,Please check in system log section.",
        color: "success",
      });
    }
  };


  const handleShowProspect = async (searchQuery) => {
    debugger;

    let query;
    query = { ...searchQuery, admin: user && user.admin };

    let search = btoa(JSON.stringify(query));
    const { history } = props;
    history.push({
      pathname: `/admin/ListProspects`,
      search: `Search=${search}`,
    });
  };

  return (
    <>
      <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => setNotify({ open: false })}
        close
      />
      {IsCampainChange && (
        <ProspectCampaign
          IsCampainChange={IsCampainChange}
          handleClose={() => {
            setIsCampainChange(false);
          }}
          user={user}
          Campaigns={Campaigns ? Campaigns : []}
          selectedRows={selectedRows}
          allProspects={data.activityData}
          totalRowCount={(data && data.total) || 0}
          RemoveAllChecks={RemoveAllChecks}
          IsShowDate={true}
        />
      )}

      {IsCallBackChange && (
        <CallBackChange
          IsCallBackChange={IsCallBackChange}
          handleClose={() => {
            setIsCallBackChange(false);
          }}
          user={user}
          Campaigns={Campaigns ? Campaigns : []}
          selectedRows={selectedRows}
          allProspects={data.activityData}
          totalRowCount={(data && data.total) || 0}
          RemoveAllChecks={RemoveAllChecks}
          IsShowDate={true}
          loadFollowUpData={loadFollowUpData}
        />
      )}

      {/* <GridItem xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center">
          <Typography variant="body1" style={{ marginLeft: "10px", color: "red", display: "flex", alignItems: "center",  paddingTop:"20px", marginBottom: "-20px" }}>
            <InfoIcon color="error" fontSize="small" style={{ marginRight: "5px" }} /> 
            <a href="#" target="_blank" style={{color:"red", fontSize:"13px"}}>Information</a>
          </Typography>
        </GridItem> */}
      {/* <Card>
        <CardHeader color="transparent"> */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h3>
            FollowUps
            {' '}
            {
              overdue && (

                <Link onClick={handleURL} to={"/admin/ViewProspect/" + overdue + '/3'}>

                  <Button color={"danger"} size="sm">  Overdue calling </Button>
                </Link>

              )


            }
            <span style={{ float: "right", fontSize: "15px" }}>
              <GridItem style={{ float: "left", marginTop: "10px" }}>
                <InfoBox name={'Schedule.FollowUps'} />
              </GridItem>
              <Button
                size="sm"
                title="Filter"
                color="info"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <i className="fa fa-filter" /> &nbsp; Filter
              </Button>
              {["manager", "owner"].indexOf(user && user.role) > -1 && (
                <Button
                  size="sm"
                  title="Filter"
                  color="info"
                  onClick={() => {
                    setIsCampainChange(true);
                  }}
                >
                  <i className="fa fa-save" /> &nbsp; Update
                </Button>
              )}
              {/* <Button
                    size="sm"
                    title="Filter"
                    color="info"
                    onClick={() => {
                      setIsCallBackChange(true);
                    }}
                  >
                    <i className="fa fa-save" /> &nbsp; Callback
                  </Button> */}
              {["manager", "owner"].indexOf(user && user.role) > -1 && (
                <Button
                  size="sm"
                  color="transparent"
                  justIcon
                  title="Export To Excel"
                  onClick={() => {
                    handleExportExcel();
                  }}
                >
                  <GetAppOutlinedIcon />
                </Button>
              )}
            </span>
          </h3>
        </GridItem>




        {open && (
          <Filters
            open={open}
            handleClose={() => {
              setOpen(false);
            }}
            ShowCampaign={true}
            filterData={filterData}
            user={user}
          />
        )}
      </GridContainer>
      {/* </CardHeader> */}
      <GridContainer>
        <GridItem xs={12} sm={4} md={2}>

          <Box
            label={"Total Followups"}
            icon={"fa fa-calendar fa-4x"}
            root="prospects"
            color="warning"
            query={TotalQuery}
            user={user}
            small={true}
            clickMe={handleShowProspect}
          //isReload={isReload}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={2}>
          <Box
            label={"Overdue Followups"}
            icon={"fa fa-calendar fa-4x"}
            root="prospects"
            color="warning"
            query={overDueQuery}
            user={user}
            small={true}
            clickMe={handleShowProspect}
          //isReload={isReload}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={2}>
          <Box
            label={"future Callbacks"}
            icon={"fa fa-calendar fa-4x"}
            root="prospects"
            color="warning"
            query={futurefollowup}
            user={user}
            small={true}
            clickMe={handleShowProspect}
          // isReload={isReload}
          />
        </GridItem>
      </GridContainer>
      <br />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {
            data.activityData && (
              <DataTable
                checkboxes={true}
                selectableRows={true}
                columns={columns}
                data={data.activityData || []}
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={data.total}
                paginationPerPage={countPerPage}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                }}
                onChangePage={handleChangePage}
                onSelectedRowsChange={handleCheckBox}
              />
            )
          }

        </GridItem>
      </GridContainer>
      {/* </Card> */}
    </>
  );
}

export default Followups;
