import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ErrorBoundary from '../../../../hoc/ErrorBoundary/ErrorBoundary';
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import TabPanel from '../../components/TabPanel/TabPanel';

// Template Components
import EmailTemplateList from '../../../CommTemplates/EmailTemplateList';
import SMSTemplateList from '../../../CommTemplates/SMSTemplateList';
import WhatsAppTemplateList from '../../../CommTemplates/WhatsAppTemplateList';

import EmailConfiguration from '../../../Configurations/EmailConfiguration';
import SMSConfiguration from '../../../SMSSetup/SMSSetupList';
import WhatsAppConfiguration from '../../../WhatsAppSetup/WhatsAppSetupList';

// Log Components
import EmailLogs from './components/EmailLogs';
import SMSLogs from './components/SMSLogs';
import WhatsAppLogs from './components/WhatsAppLogs';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  }
}));

const TemplatesTab = ({ user, history }) => {
  const classes = useStyles();
  const [activeTemplate, setActiveTemplate] = useState(0);
  const [emailTab, setEmailTab] = useState(0);
  const [smsTab, setSmsTab] = useState(0);
  const [whatsappTab, setWhatsappTab] = useState(0);

  const handleTemplateChange = (event, newValue) => {
    setActiveTemplate(newValue);
  };

  const templateTabs = [
    {
      tabName: "Email Templates",
      tabContent: (
        <ErrorBoundary name="EmailTemplates">
          <CustomTabs
            title=""
            headerColor="primary"
            tabs={[
              {
                orientation:"vertical",
                tabName: "Template",
                tabContent: (
                  <EmailTemplateList user={user} history={history} />
                )
              },
              {
                tabName: "Configuration",
                tabContent: (
                  <EmailConfiguration user={user} />
                )
              },
              {
                tabName: "Logs",
                tabContent: (
                  <EmailLogs user={user} />
                )
              }
            ]}
            value={emailTab}
            onChange={(e, v) => setEmailTab(v)}
          />
        </ErrorBoundary>
      )
    },
    {
      tabName: "SMS Templates",
      tabContent: (
        <ErrorBoundary name="SMSTemplates">
          <CustomTabs
            title=""
            headerColor="primary"
            tabs={[
              {
                tabName: "Template",
                tabContent: (
                  <SMSTemplateList user={user} history={history} />
                )
              },
              {
                tabName: "Configuration",
                tabContent: (
                  <SMSConfiguration user={user} />
                )
              },
              {
                tabName: "Logs",
                tabContent: (
                  <SMSLogs user={user} />
                )
              }
            ]}
            value={smsTab}
            onChange={(e, v) => setSmsTab(v)}
          />
        </ErrorBoundary>
      )
    },
    {
      tabName: "WhatsApp Templates",
      tabContent: (
        <ErrorBoundary name="WhatsAppTemplates">
          <CustomTabs
            title=""
            headerColor="primary"
            tabs={[
              {
                tabName: "Template",
                tabContent: (
                  <WhatsAppTemplateList user={user} history={history} />
                )
              },
              {
                tabName: "Configuration",
                tabContent: (
                  <WhatsAppConfiguration user={user} />
                )
              },
              {
                tabName: "Logs",
                tabContent: (
                  <WhatsAppLogs user={user} />
                )
              }
            ]}
            value={whatsappTab}
            onChange={(e, v) => setWhatsappTab(v)}
          />
        </ErrorBoundary>
      )
    }
  ];

  return (
    <div className={classes.root}>
      <CustomTabs
        title=""
        headerColor="info"
        tabs={templateTabs}
        value={activeTemplate}
        onChange={handleTemplateChange}
      />
    </div>
  );
};

TemplatesTab.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default TemplatesTab;
