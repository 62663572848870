import React, { Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
// core components
import { API_POST } from "../../services/api.service";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import LabelOffIcon from '@material-ui/icons/LabelOff';
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchBox from "./SearchBox";
import { useSelector } from "react-redux";
import HistoryIcon from "@material-ui/icons/History";
import DataTable from "react-data-table-component";
import dayjs from "dayjs";

import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import SettingsIcon from "@material-ui/icons/Settings";
import history from "../../history";
import { getQueryParams, maskNumber } from "../../services/Common";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import "./Style/Prospect.css";
import ViewCallDetails from "./ViewCallDetails";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { Badge, Snackbar } from "@material-ui/core";
import ProspectFresh from "./ProspectFresh";
import Loader from "../../components/Loader/Loader";
import InfoBox from "../../components/InfoIcon/InfoIcon";
import { AirplanemodeInactiveSharp } from "@material-ui/icons";



const UpdateDisposition = React.lazy(() => import("./UpdateDisposition"));
const ProspectCampaign = React.lazy(() => import("./ProspectCampaign"));
const ProspectAssignment = React.lazy(() => import("./ProspectAssignment"));
const ProspectDelete = React.lazy(() => import("./ProspectDelete"));
const ProspectFilter = React.lazy(() => import("../Filter/ProspectFilter"));
const ProspectSettings = React.lazy(() => import("./ProspectSettings"));


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  arrow: {
    display: "inline-grid",
    width: "20px",
  },
};

const Prospects = (props) => {
  let [_SearchBoxVal] = useSelector(({ payload }) => [
    payload && payload.SearchBoxVal != null ? payload.SearchBoxVal : "",
  ]);
  let multiFilterQuery = [];
  const { classes, user, cid } = props;
  const [filterCol, setFilterCol] = React.useState([]);
  const [, setPage] = React.useState(0);
  const [skip, setSkip] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    ["manager", "owner"].indexOf(user.role) > -1 ? 50 : 10
  );
  const [totalRowCount, settotalRowCount] = React.useState(0);
  const [selectValue] = React.useState({});
  const [sortVal] = React.useState(0);
  const [searchBox, setSearchBox] = React.useState("");
  const [open, setOpen] = useState(false);
  const [IsSettingsOpen, setIsSettingsOpen] = useState(false);
  const [IsCallDetailsOpen, setIsCallDetailsOpen] = useState(false);
  const [callDetailsQuery, setcallDetailsQuery] = useState("");
  const [IsDisposition, setIsDisposition] = useState(false);
  const [IsCampainChange, setIsCampainChange] = useState(false);
  const [IsProspectDelete, setIsProspectDelete] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [AllProspects, setAllProspects] = useState([]);
  const [maxRepeatRecord, setMaxRepeatRecord] = useState(0);
  const [Campaigns, setCampaigns] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [IsAssignment, setIsAssignment] = useState(false);
  const [prospectFresh, setProspectFresh] = useState(false);
  const [IsFreshLeads, setIsFreshLeads] = useState(false);
  const [isOverdue, setIsOverdue] = useState(false);
  const [inActiveCamp, setInActiveCamp] = useState(false);
  const [IsUnAssignedLeads, setIsUnAssignedLeads] = useState(false);
  const [Query, setQuery] = useState("");
  const [IsRefresh, setIsRefresh] = useState(false);
  const [columns, setColumns] = useState([]);
  const [toggledClearRows, setToggledClearRows] = useState(false);
  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });
  const [data, setData] = useState({});


  const handleChangePage = (newPage) => {
    setPage(newPage);
    setSkip((newPage - 1) * rowsPerPage);
  };

  const handleCheckBox = ({ selectedRows }) => {
    console.log("selectedRows", selectedRows);
    setSelectedRows(selectedRows);
  };

  const RemoveAllChecks = (flag) => {
    var checkboxes = document.querySelectorAll('input[type="checkbox"]');
    for (var i = 0; i < checkboxes.length; i++) checkboxes[i].checked = flag;
  };

  const pageLoad = () => {
    setIsUnAssignedLeads(
      localStorage.getItem("IsUnAssignedLeads") == "true" ? true : false
    );

    setIsFreshLeads(
      localStorage.getItem("IsFreshLeads") == "true" ? true : false
    );
  };
  let apicalled = false;
  const loadData = async () => {
    if (apicalled) {
      return;
    }
    apicalled = true;
    let registerRequest;
    let con;
    let body;

    if (!user || (user && !user.admin)) {
      return;
    }

    try {
      // const urlParams = new URLSearchParams(window.location.search);
      //let search = urlParams.get("Search");
      let search = getQueryParams("Search", window.location.href);

      //  let searchParams = getQueryParams("?IsFreshLeads", window.location.href);

      // alert(JSON.stringify(window.location.search));

      let endpoint = getQueryParams("endpoint", window.location.href);


      if (window.location.search.includes('IsFreshLeads') || IsFreshLeads) {

        setIsFreshLeads(true);

      }

      if (isOverdue) {
        setIsOverdue(true);
      }

      if(inActiveCamp){
        setInActiveCamp(true);
      }

      let customQuery = false;

      if (window.location.search.includes('query')) {
        customQuery = true;
        search = getQueryParams("query", window.location.href);


      }

      // alert(JSON.stringify(search));


      if (!search && !customQuery) {
        con =
          multiFilterQuery.length > 0
            ? SearchBox.getMultiFilterQuery(
              multiFilterQuery,
              cid,
              user,
              IsUnAssignedLeads
            )
            : SearchBox.getQueryData(searchBox, cid, user);
        if (!con) {
          return;
        }

        //setSelectedRows([]);

        if (isOverdue) {

          con = {
            ...con,
            $and: [
              {
                IsActive: true
              },
              { $or: [{ callback: { $ne: null } }, { appointment: { $ne: null } }] }
            ],
            callback: { $lt: new Date() }
          }
        }


        if(inActiveCamp){
          con = {
            ...con,
            inActiveCampaign: true
          };
        }

        if (IsFreshLeads)
          con = {
            ...con,
            open: true,
            disposition: { $exists: false },
          };

        if (IsUnAssignedLeads)
          con = {
            ...con,
            open: true,
            assignTo: { $exists: false },
          };

        if (user && ["field_exc", "agent"].indexOf(user.role) > -1) {
          con = { ...con, assignTo: user && user.username };
        }
        con = { ...con, admin: user && user.admin };
      } else {
        search = JSON.parse(atob(search));
        con = { ...search };

        if (isOverdue) {

          con = {
            ...con,
            $and: [
              {
                IsActive: true
              },
              { $or: [{ callback: { $ne: null } }, { appointment: { $ne: null } }] }
            ],
            callback: { $lt: new Date() }
          }
        }
        if(inActiveCamp){
          con = {
            ...con,
            inActiveCampaign: true
          };
        }

        if (IsFreshLeads)
          con = {
            ...con,
            open: true,
            disposition: { $exists: false },
          };

        if (IsUnAssignedLeads)
          con = {
            ...con,
            open: true,
            assignTo: { $exists: false },
          };
      }


      if (user && ["manager", "owner"].indexOf(user.role) > -1) {
        setRowsPerPage(50);
      }

      body = {
        limit: ["manager", "owner"].indexOf(user.role) > -1 ? 50 : rowsPerPage,
        skip: skip,
        sort: selectValue && sortVal ? selectValue : { createdOn: -1,  revisitedOn: -1 },
      };

      if (user && ["field_exc", "agent"].indexOf(user.role) > -1) {
        con = { ...con, assignTo: user && user.username };
      }

      con = { ...con, admin: user && user.admin, IsActive: true };
      setQuery(con);



      registerRequest = await API_POST("action/getPaginationData", {
        root: "prospects",
        querydata: con,
        body: body,

      });



      apicalled = false;

      if (registerRequest && registerRequest.status == 200) {
        setFilterCol(registerRequest.data.prospectFileds);
        setCampaigns(registerRequest.data.Campaigns);
        let selectedcamp = registerRequest.data.Campaigns.filter((v) => con.cid == v._id);
        setSelectedCampaigns(selectedcamp);
        setAllProspects(registerRequest.data.activityData);
        setMaxRepeatRecord(registerRequest.data.maxRepeatRecord);
        setData({
          data: registerRequest.data.activityData || [],
          total: registerRequest.data.rowCount,
        });
        setToggledClearRows(false);

        await bindHeader(
          registerRequest.data.prospectFileds,
          registerRequest.data.activityData
        );

        for (
          let index = 0;
          index < registerRequest.data.activityData.length;
          index++
        ) {
          settotalRowCount(registerRequest.data.rowCount);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleURL = () => {
    window.localStorage.setItem("LastURL", window.location.href);
  };

  const bindHeader = async (prospectFileds) => {
    let defaultColumns = [
      {
        name: "Prospect",
        width: "130px",
        cell: (value) => {
          // if (value.name === "_id") {
          let val = "";
          let  campaignIsActive = value.CampainData && value.CampainData.length > 0 ? value.CampainData[0].IsActive : true;

          if (value.firstName && value.lastName) {
            val = value.firstName + " " + value.lastName;
          } else if (value.firstName && value.firstName != ' ') {
            val = value.firstName;
          } else {
            if (
              value.CampainData &&
              value.CampainData.length > 0 &&
              value.CampainData[0].phoneNumberMasking
            ) {
              val = maskNumber(value.phone);
            } else {
              val = value.phone;
            }
          }

          return (
            <>
              <Link onClick={handleURL} to={"/admin/ViewProspect/" + value._id + "/1"}>
                {val} {!campaignIsActive &&  <AirplanemodeInactiveSharp style={{width:"20px"}} title={"Inactive Campaign"}/>}
              </Link>
            </>

          );
        },
      },

      {
        name: "Created On",
        cell: (row) => (
          <span>
          {new Date(row.createdOn).toLocaleString()}
          </span>

        ),
      },
      {
        name: "Last Created On",
        cell: (row) => (
          <span>
          {new Date(row.revisitedOn || row.createdOn).toLocaleString()}
          </span>

        ),
      },
      {
        name: "Callback",
        cell: (row) => (
          <span>
            {row.callback &&
              new Date(row.callback).toLocaleString()}
          </span>

        ),
      },
      {
        name: "Last Called",
        cell: (row) => (
          <span>
            {row.LastCalled &&
              new Date(row.LastCalled).toLocaleString()}
          </span>

        ),
      },

      {
        name: "Tags",
        cell: (row) => {
          return (
            <>
              <span>{(row.tags && Array.isArray(row.tags) && row.tags.length) ? (row.tags.map((v) => { return v.tag_value })).toString() : null}</span>
            </>
          );
        },
      },
      {
        name: "Campaign",
        width: "150px",
        cell: (row) => {
          return (
            <>
              {row.CampainData && row.CampainData.length > 0
                ? row.CampainData[0].name
                : "-"}
            </>
          );
        },
      },
      {
        name: "Assigned Agent",
        cell: (row) => {
          return <span style={{wordWrap: "break-word", width: "100%"}}>{row.assignTo ? row.assignTo : "-"}</span>;
        }
      },
      {
        name: "Disposition",
        width: "150px",
        cell: (value) => {
          let arr = [];
          if (value.dispositionArray && value.dispositionArray.length > 0) {
            for (
              let index = 0;
              index < value.dispositionArray.length;
              index++
            ) {
              const element = value.dispositionArray[index];
              arr.push(element.disposition_value);
            }
          }

          return arr.join(" > ");
        },
      },
    ];

    if (prospectFileds && prospectFileds.length > 0) {
      for (let index = 0; index < prospectFileds.length; index++) {
        const item = prospectFileds[index];

        if (item.IsListView && item.display_Name == "Repeat") {

          defaultColumns.splice(0, 0, {
            name: "Repeat",
            width: "70px",
            cell: (value) => {
              // if (value.name === "_id") {
              let val = "";
              let repeat = value.updatedCount ? value.updatedCount : 0;


              return (
                <>

                  {

                    repeat ? <Badge badgeContent={repeat} color="primary">
                      <AddToPhotosIcon />
                    </Badge> : null

                  }


                </>

              );
            },
          });

          continue;

        }


        if (
          item.IsListView &&
          item.field_name !== "callback" &&
          item.field_name !== "LastCalled" &&
          item.field_name !== "createdOn" &&
          item.field_name !== "disposition"
        ) {
          if (item.isPhone || item.field_type == "phone") {
            defaultColumns.splice(defaultColumns.length - 4, 0, {
              name: item.display_Name,
              //selector: item.field_name,
              cell: (row) => (
                <span>
                  {row.CampainData &&
                    row.CampainData.length > 0 &&
                    row.CampainData[0].phoneNumberMasking
                    ? maskNumber(row[item.field_name])
                    : row[item.field_name]}
                </span>
              ),
            });
            
          } 
          else if (item.field_type == "datetime") {
            defaultColumns.splice(defaultColumns.length - 4, 0, {
              name: item.display_Name,
              
              cell: (row) => (
                  <span>{new Date(row[item.field_name]).toLocaleString()}</span>
              ),
            });
          }
          else {
            defaultColumns.splice(defaultColumns.length - 4, 0, {
              name: item.display_Name,
              //selector: item.field_name,
              cell: (row) => <span style={{wordWrap: "break-word", width: "100%"}}>{row[item.field_name]}</span>,
            });
          }
        }
      }
    }
    defaultColumns.splice(defaultColumns.length, 0, {
      name: "Priority",
      cell: (row) => {
        return (
          <>
            {(row.Priority == 0 || row.Priority == undefined) && "Default"}
            {row.Priority == 1 && "Medium"}
            {row.Priority == 2 && "High"}
            {(row.Priority == undefined || row.Priority < 2) && <ArrowUpwardIcon style={{ cursor: "pointer" }}
              onClick={(e) => {
                handlePriority(e, row);
              }}

            />
            }
          </>
        );
      },
    });


    defaultColumns.splice(defaultColumns.length, 0, {
      name: "CallHistory",
      width: "auto",
      cell: (row) => {
        return (
          <>
            {row.CallStatus == "Contacted" ? (
              <Button
                size="sm"
                color="success"
                onClick={(e) => {
                  handleCallDetails(e, row);
                }}
              >
                <HistoryIcon />
                Contacted
              </Button>
            ) : (
              <Button size="sm" color="danger">
                Not Contacted
              </Button>
            )}
          </>
        );
      },
    });

    setColumns(defaultColumns);
  };

  const handleProspectCampain = () => {
    setIsCampainChange(true);
  };
  const handleRechurnProspects = () => {
    // selectedRows={selectedRows}
    //       Query={Query}
  };


  const handlequery = async (data, query) => {
    data = data && data[0];
    query = {
      $and: [
        { admin: user && user.admin },
        {
          IsActive: true,
        },
      ],
    };

    if (user && ["field_exc", "agent"].indexOf(user.role) > -1) {
      query = { ...query, assignTo: user && user.username };
    }
    if (data.disposition && data.disposition.length > 0) {
      query = { ...query, disposition: { ["$in"]: data.disposition } };
    }
    query = { ...data, ...query, admin: user && user.admin };

    if (cid) {
      query = { ...query, campId: cid };
    }

    return query;
  };

  const handlePageLoad = async (data) => {
    debugger;
    let query;
    query = await handlequery(data, query);
    let search = btoa(JSON.stringify(query));

    history.push({
      pathname: `/admin/ListProspects`,
      search: `Search=${search}`,
    });
    loadData();
  };

  const filterData = (data) => {
    debugger;
    multiFilterQuery = data;
    setOpen(false);
    handlePageLoad(data);
  };

  const handlePriority = async (e, element) => {
    debugger;
    let registerRequest;
    let Priority = element.hasOwnProperty("Priority")
      ? element.Priority + 1
      : 1;
    let body = { Priority: Priority };
    registerRequest = await API_POST("action/update", {
      root: "prospects",
      body,
      querydata: { _id: element._id },
    });

    if (registerRequest && registerRequest.status == 200) {
      loadData();
    }
  };

  const handleCallDetails = async (e, element) => {
    let CallDetailsQuery = { phone: element.phone, caller: user };
    setIsCallDetailsOpen(true);
    setcallDetailsQuery(CallDetailsQuery);
  };

  useEffect(() => {
    pageLoad();
    loadData();
  }, [rowsPerPage, skip, user, sortVal, IsFreshLeads, isOverdue, IsUnAssignedLeads,inActiveCamp]);

  useEffect(() => { }, [columns]);

  const handleUnAssignedLeads = (e) => {
    setIsUnAssignedLeads(!IsUnAssignedLeads);
    localStorage.setItem("IsUnAssignedLeads", e.target.checked);
  };

  const handleOverdue = (e) => {
    setIsOverdue(!isOverdue);
    localStorage.setItem("isOverdue", e.target.checked);
  }

  const handleInActiveCampaign = (e) => {
    setInActiveCamp(!inActiveCamp);
    localStorage.setItem("inActiveCamp", e.target.checked);
  }

  const handleFrshLead = (e) => {
    setIsFreshLeads(!IsFreshLeads);
    localStorage.setItem("IsFreshLeads", e.target.checked);
    loadData();
  };

  const handleExportExcel = async (e) => {
    let downloadFile = await API_POST(`action/ExportExcel`, {
      querydata: Query,
      //body: { cid, admin: user.admin, user: user.username,page:"prospects" },
      body: { admin: user.admin, user: user.username, page: "prospects" },
    });
    if (downloadFile.status == 200) {
      setNotify({
        open: true,
        message: "File is in process,Please check in system log section.",
        color: "success",
      });
    }
  };

  // useEffect(() => {
  //   if (_SearchBoxVal) {
  //     debugger;
  //     setSearchBox(_SearchBoxVal);
  //     setIsRefresh(true);
  //   }
  // }, [_SearchBoxVal]);

  // useEffect(() => {
  //   if (IsRefresh) {
  //     debugger;
  //     setIsRefresh(false);
  //     loadData();
  //   }
  // }, [IsRefresh]);

  // if (Prospects && Prospects.length > 0) {
  return (
    <>
      <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => setNotify({ open: false })}
        close
      />
      {open && (
        <Suspense fallback={<div></div>}>
          <ProspectFilter
            filterCol={filterCol}
            repeatFilter={true}
            open={open}
            filterData={filterData}
            handleClose={() => {
              setOpen(false);
            }}
            data={filterCol ? filterCol : []}
            user={user}
            cid={cid}
            Campaigns={Campaigns}
          />
        </Suspense>
      )}
      {IsSettingsOpen && (
        <Suspense fallback={<div></div>}>
          <ProspectSettings
            IsSettingsOpen={IsSettingsOpen}
            handleClose={() => {
              setIsSettingsOpen(false);
            }}
            data={filterCol ? filterCol : []}
            user={user}
          />
        </Suspense>
      )}
      {IsDisposition && (
        <Suspense fallback={<div></div>}>
          <UpdateDisposition
            IsDisposition={IsDisposition}
            handleClose={() => {
              setIsDisposition(false);
            }}
            user={user}
            selectedRows={selectedRows}
            Query={Query}
            totalRowCount={totalRowCount}
            RemoveAllChecks={RemoveAllChecks}
          />
        </Suspense>
      )}
      {IsCampainChange && (
        <Suspense fallback={<div></div>}>
          <ProspectCampaign
            Query={Query}
            IsCampainChange={IsCampainChange}
            handleClose={() => {
              setIsCampainChange(false);
            }}
            onChange={() => {
              setToggledClearRows(!toggledClearRows);
              loadData();
            }}
            Campaigns={Campaigns ? Campaigns : []}
            user={user}
            selectedRows={selectedRows}
            allProspects={AllProspects}
            totalRowCount={totalRowCount}
            RemoveAllChecks={RemoveAllChecks}
          />
        </Suspense>
      )}
      {IsAssignment && (
        <Suspense fallback={<div></div>}>
          <ProspectAssignment
            IsAssignment={IsAssignment}
            handleClose={() => {
              setIsAssignment(false);
            }}
            onChange={() => {
              setToggledClearRows(!toggledClearRows);
              loadData();
            }}
            selectedRows={selectedRows}
            Query={Query}
            totalRowCount={totalRowCount}
            user={user}
            cid={cid}
            RemoveAllChecks={RemoveAllChecks}
          />
        </Suspense>
      )}

      {prospectFresh && (
        <Suspense fallback={<div></div>}>
          <ProspectFresh
            IsAssignment={prospectFresh}
            handleClose={() => {
              setProspectFresh(false);
            }}
            onChange={() => {
              setToggledClearRows(!toggledClearRows);
              loadData();
            }}
            selectedRows={selectedRows}
            Query={Query}
            totalRowCount={totalRowCount}
            user={user}
            cid={cid}
            RemoveAllChecks={RemoveAllChecks}
          />
        </Suspense>
      )}


      {["manager", "owner"].indexOf(user && user.role) > -1 &&
        IsProspectDelete && (
          <Suspense fallback={<div></div>}>
            <ProspectDelete
              Query={Query}
              IsProspectDelete={IsProspectDelete}
              handleClose={() => {
                setIsProspectDelete(false);
              }}
              onChange={() => {
                setToggledClearRows(!toggledClearRows);
                loadData();
              }}
              Campaigns={Campaigns ? Campaigns : []}
              user={user}
              selectedRows={selectedRows}
              totalRowCount={totalRowCount}
              RemoveAllChecks={RemoveAllChecks}
            />
          </Suspense>
        )}

      {IsCallDetailsOpen && (
        <ViewCallDetails
          IsCallDetailsOpen={true}
          handleClose={() => {
            setIsCallDetailsOpen(false);
          }}
          user={user}
          callDetailsQuery={callDetailsQuery}
        />
      )}


      <Card>
        <CardHeader color="transparent">
          <h3>
            Prospects
            <span style={{ float: "right", fontSize: "15px" }}>






              {["manager", "owner", "agent"].indexOf(user && user.role) > -1 && (
                <>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkbox"
                    checked={isOverdue}
                    // onClick={() => { setIsFreshLeads(!IsFreshLeads) }}
                    onClick={handleOverdue}
                  />
                  OverDue
                </>
              )}
              {["manager", "owner", "agent"].indexOf(user && user.role) > -1 && (
                <>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkbox"
                    checked={inActiveCamp}
                    // onClick={() => { setIsFreshLeads(!IsFreshLeads) }}
                    onClick={handleInActiveCampaign}
                  />
                  In-Active Campaign
                </>
              )}
              {["manager", "owner"].indexOf(user && user.role) > -1 && (
                <>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkbox"
                    checked={IsFreshLeads}
                    // onClick={() => { setIsFreshLeads(!IsFreshLeads) }}
                    onClick={handleFrshLead}
                  />
                  Fresh
                </>
              )}
              {["manager", "owner"].indexOf(user && user.role) > -1 && (
                <>
                  <input
                    type="checkbox"
                    name="checkbox"
                    checked={IsUnAssignedLeads}
                    //onClick={() => { setIsUnAssignedLeads(!IsUnAssignedLeads) }}
                    onClick={handleUnAssignedLeads}
                  />
                  UnAssigned
                </>
              )}
              &nbsp;&nbsp;
              <Button
                size="sm"
                title="Filter"
                color="primary"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <i className="fa fa-filter" /> &nbsp; Filter
              </Button>

              {["manager", "owner"].indexOf(user && user.role) > -1 && cid && (
                <Button
                  size="sm"
                  title="Change Campaign"
                  color="primary"
                  onClick={handleProspectCampain}
                >
                  <i className="fa fa-bullhorn" /> &nbsp; Change Campaign
                </Button>
              )}
              {["manager", "owner"].indexOf(user && user.role) > -1 && cid && selectedCampaigns && selectedCampaigns.length && selectedCampaigns[0].enableFreshProspect && (
                <Button
                  size="sm"
                  color="primary"
                  title="Fresh Prospect"
                  onClick={() => {
                    setProspectFresh(true);
                  }}
                >
                  <RemoveCircle /> &nbsp; Make Fresh
                </Button>
              )}
              {["manager", "owner"].indexOf(user && user.role) > -1 && cid && (
                <Button
                  size="sm"
                  color="primary"
                  title="Assign To"
                  onClick={() => {
                    setIsAssignment(true);
                  }}
                >
                  <AssignmentTurnedInIcon /> &nbsp; Assign To
                </Button>
              )}

              {["owner"].indexOf(user && user.role) > -1 && cid && selectedCampaigns && selectedCampaigns.length && selectedCampaigns[0].enableDeleteProspect && (
                <Button
                  size="sm"
                  color="primary"
                  title="Delete"
                  onClick={() => {
                    setIsProspectDelete(true);
                  }}
                >
                  <DeleteIcon /> &nbsp; Delete
                </Button>
              )}

              {["manager", "owner"].indexOf(user && user.role) > -1 && cid && (
                <Button
                  size="sm"
                  color="primary"
                  title="Change Disposition"
                  onClick={() => {
                    setIsDisposition(true);
                  }}
                >
                  <LabelOffIcon /> &nbsp; Change Disposition
                </Button>
              )}
              {["manager", "owner"].indexOf(user && user.role) > -1 && (
                <Button
                  size="sm"
                  color="transparent"
                  justIcon
                  title="Settings"
                  onClick={() => {
                    setIsSettingsOpen(true);
                  }}
                >
                  <SettingsIcon />
                </Button>
              )}
              {["owner"].indexOf(user && user.role) > -1 && (
                <Button
                  size="sm"
                  color="transparent"
                  justIcon
                  title="Export To Excel"
                  onClick={() => {
                    handleExportExcel();
                  }}
                >
                  <GetAppOutlinedIcon />
                </Button>
              )}
            </span>
            <InfoBox name={'Prospects'} />
          </h3>
        </CardHeader>
        <CardBody style={{ minHeight: '200px' }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="propspect__table">
                {data && !data.data && <div> <Loader></Loader></div>}
                {data && data.data && <DataTable
                  checkboxes={true}
                  selectableRows={true}
                  columns={columns}
                  data={data.data}
                  highlightOnHover
                  pagination
                  paginationServer
                  paginationTotalRows={data.total}
                  paginationPerPage={rowsPerPage}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  onChangePage={handleChangePage}
                  onSelectedRowsChange={handleCheckBox}
                  clearSelectedRows={toggledClearRows}
                />}
              </div>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </>
  );
};

Prospects.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Prospects);
