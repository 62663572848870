import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Tabs, Tab } from '@material-ui/core';
import TabPanel from '../../components/TabPanel/TabPanel';

// Settings Components
import BasicSettings from './components/BasicSettings';
import NotificationSettings from './components/NotificationSettings';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  paper: {
    padding: theme.spacing(2)
  },
  tabPanel: {
    padding: theme.spacing(2)
  }
}));

const SettingsTab = ({ user }) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Basic Settings" />
          <Tab label="Notification" />
        </Tabs>

        <TabPanel value={activeTab} index={0}>
          <BasicSettings user={user} />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <NotificationSettings user={user} />
        </TabPanel>
      </Paper>
    </div>
  );
};

SettingsTab.propTypes = {
  user: PropTypes.object.isRequired
};

export default SettingsTab;
