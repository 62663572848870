import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Link } from "react-router-dom";
import { API_GET, API_POST } from "../../services/api.service";
import _ from "underscore";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
// import Filters from "./Filter";
import Button from "components/CustomButtons/Button.jsx";


import Highcharts from 'highcharts';
import drilldown from 'highcharts/modules/drilldown.js';
import HighchartsReact from 'highcharts-react-official';
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import ErrorBoundary from "../../hoc/ErrorBoundary";
import Followups from "../Schedule/Followups";
import Appointment from "../Schedule/Appointment";
import ReportsStats from "../ReportStats/reportsStats";
import Files from "../Campaigns/Files";
import ProspectHistory from "../ProspectHistory/ProspectHistory";
import Metabase from "../Metabase/dashboard";
import ApiLog from "./ApiLog";

//import _ from "underscore";


function SystemLog(props) {
  const { user } = props;
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [disposition, setDisposition] = useState([]);
  let dispositionColumnDone = 0;


  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    setActiveTab(parseInt(tab));

   
  }, [user]);



  

  const handleChange = (event, value) => {
    const { history } = props;
    setActiveTab(value);
    history.push('?tab=' + value)
  };

  return (
    <>
      <>



        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title=""
            TabProps={{
              onChange: handleChange,
            }}
            selectedValue={activeTab}
            headerColor="info"
            tabs={[
              {
                tabName: "Files",
                tabContent: (
                  <>
                    <CardBody>
                      <Files props={props} user={props.user} />
                    </CardBody>
                  </>
                ),
              },
              {
                tabName: "Prospect Log",
                tabContent: (
                  <CardBody>
                    <ProspectHistory props={props} user={props.user} />

                  </CardBody>
                ),
              },
              {
                tabName: "API Log",
                tabContent: (
                  <CardBody>

                    <ApiLog props={props} user={props.user} />
                  </CardBody>
                ),
              },
             
            ]}
          />
        </GridItem>



      </>
    </>
  );
}

export default SystemLog;


