import React from 'react';
import { Modal, Button, Typography } from '@material-ui/core';

function MuiModal({title, children, openTrigger}) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="" size='small' color="primary" onClick={handleOpen}>
        {openTrigger}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#fff',
          padding: '20px',
          width: '400px',
          borderRadius: '10px',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
        }}>
          <Typography variant="h6" id="modal-title">
            {title}
          </Typography>
          <Typography variant="body2" id="modal-description" style={{ marginTop: '10px', overflow: 'auto', maxHeight: '300px' }}>
            {children}
          </Typography>
          <Button variant="outlined" color="primary" onClick={handleClose} style={{ marginTop: '20px' }}>
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default MuiModal;