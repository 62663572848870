import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Link } from "react-router-dom";
import { API_POST } from "../../services/api.service";
import _ from "underscore";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Filters from "./Filters";
import Button from "components/CustomButtons/Button.jsx";
import ProspectCampaign from "../Prospects/ProspectCampaign";
import CallBackChange from "./CallBackChange";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { Snackbar } from "@material-ui/core";
import Box from "../Dashboard/Box";
// import InfoIcon from "@material-ui/icons/Info";
// import Typography from "@material-ui/core/Typography";
import { array } from "prop-types";
import InfoBox from "../../components/InfoIcon/InfoIcon";
import { getManagerTeam } from "../common/helper";


const columns = [
  {
    name: "Prospect",
    cell: (row) => <span>{customCell(row)}</span>,
  },
  {
    name: "Disposition",
    cell: (row) => (
      <span>
        {_.pluck(row.dispositionArray, "disposition_value").join(" > ")}
      </span>
    ),
  },
  {
    name: "Scheduled Time",
    cell: (row) => (
      <span>
        {row.callback && new Date(row.callback).toLocaleString()}
      </span>
    ),
  },
  {
    name: "Campaign",
    cell: (row) => (
      <span>
        {row.CampainData && row.CampainData.length > 0
          ? row.CampainData[0].name
          : "-"}
      </span>
    ),
  },
  {
    name: "Remarks",
    cell: (row) => <span>{row.remarks}</span>,
  },
  {
    name: "Last Called Time",
    cell: (row) => (
      <span>
        {row.LastCalled &&
          new Date(row.LastCalled).toLocaleString()}
      </span>
    ),
  },
  {
    name: "LastCaller",
    cell: (row) => <span>{row.LastCaller}</span>,
  },
  {
    name: "Scheduled by",
    cell: (row) => <span>{row.assignTo}</span>,
  },
];
const handleURL = () => {
  window.localStorage.setItem("LastURL", window.location.href);
};

const customCell = (value) => {
  let val = "";
  if (value.firstName && value.lastName) {
    val = value.firstName + " " + value.lastName;
  } else if (value.firstName && value.firstName !=' ') {
    val = value.firstName;
  } else {
    val = value.phone;
  }
  return (
    <Link onClick={handleURL} to={"/admin/ViewProspect/" + value._id+'/1'}>
      {val}
    </Link>
  );
};

const checkprospect=[];

function Overdue(props) {
  const { user } = props;
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [skip, setSkip] = React.useState(0);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 0));
  const [selectedUser, setSelectedUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [IsCampainChange, setIsCampainChange] = useState(false);
  const [IsCallBackChange, setIsCallBackChange] = useState(false);

  const [IsRefresh, setIsRefresh] = useState(false);
  const [Campaigns, setCampaigns] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [AllProspects, setAllProspects] = useState([]);
  const [SelectedCampaign, setSelectedCampaign] = useState([]);
  const [Query, setQuery] = useState("");
  const [load, setLoad] = useState(false);
  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });
  const [TotalQuery, setTotalQuery] = useState(null);

  const [overDueQuery, setOverDueQuery] = useState(null);
  // const [overDueQuery, setOverDueQuery] = useState();

  const [futurefollowup, setFuturefollowup] = useState(null);

  //const [futurefollowup, setFuturefollowup] = useState();

  const [mobileNo,setMobileNo]=useState(null);
  const [overdue,setOverdue]=useState(null);


  const handleChangePage = (newPage) => {
    setPage(newPage);
    setSkip((newPage - 1) * countPerPage);
  };

  const pageLoadquery = async () => {
    const data = {
      SelectedCampaign: SelectedCampaign,
      selectedUser: selectedUser,
      mobileNo:mobileNo,
      startDate:startDate,
      endDate:endDate,
    };
    const query = await handlequery(data);
    return query;
  };



  const tempcheckprospect=[];
  const loadFollowUpData = async (customQuery = null) => {
    let con = customQuery || await pageLoadquery();
    let body = {
      limit: countPerPage,
      skip: skip,
      sort: { callback: 1 },
    };

    let prospect_res = await API_POST("action/getdata", {
      root: "prospect_fields",
      con: {admin:con.admin,IsSchedule :{$exists :true} },
    });

    if(prospect_res.data.length>0){
      let prospectFileds=prospect_res.data;
      for (let index = 0; index < prospectFileds.length; index++) {
        let checkexists = columns.find(v => v.name == prospectFileds[index].display_Name);
        if(!checkexists && prospectFileds[index].IsSchedule == true){
          columns.push({
            name: prospectFileds[index].display_Name,
            selector: prospectFileds[index].field_name,
          });
        }
      }
    }

    if(user.role == 'manager'){
      let managerTeam =  await getManagerTeam(user);
      const managetCallers = { assignTo: { $in: managerTeam.map(v=>v.username) } };
      if (con['$and']) {
        con['$and'].push(managetCallers);
      } else {
        con['$and'] = [managetCallers];
      }
    }

    let res = await API_POST("action/getPaginationDataActivity", {
      root: "prospects",
      querydata: con,
      body: body,
    });

    setData(res.data || []);
    setCampaigns((res.data && res.data.Campaigns) || []);
  };

  const handleUrl = async (data) => {
    const query = await handlequery(data);
    setQuery(query);
    await loadFollowUpData(query);
    return true;
  };

  useEffect(() => {
    const loadPage = async () => {
      const query = await pageLoadquery();
      await loadFollowUpData(query);
    };
    loadPage();
  }, [page]);

  useEffect(() => {
    if (user) {
     // loadAggData();
    }
  }, [user, user && user.admin]);

 
  const filterData = (data) => {
    console.log(data);
    setStartDate(data.startDate);
    setEndDate(data.endDate);
    setSelectedUser(data.selectedUser);
    setSelectedCampaign(data.SelectedCampaign);
    setIsRefresh(true);
    setOpen(false);
    setMobileNo(data.mobileNo);

    handleUrl(data);
  };



  const RemoveAllChecks = (flag) => {
    var checkboxes = document.querySelectorAll('input[type="checkbox"]');
    for (var i = 0; i < checkboxes.length; i++) checkboxes[i].checked = flag;
  };

  const handleCheckBox = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };
  const handleExportExcel = async (e) => {
    let downloadFile = await API_POST(`action/ExportExcel`, {
      querydata: Query,
      body: { admin: user.admin, user: user.username, page: "FollowUp" },
    });
    if (downloadFile.status == 200) {
      setNotify({
        open: true,
        message: "File is in process,Please check in system log section.",
        color: "success",
      });
    }
  };


  const handlequery = async (data) => {
    let query = {
      $and: [
        {
          IsActive: true,
        },
        {
          open: true 
        },
      ],
      callback: {
        $lte: new Date(),
      },
    };

    if (data.SelectedCampaign && data.SelectedCampaign.length > 0) {
      query = { ...query, cid: { ["$in"]: data.SelectedCampaign } };
    }

    if (data.selectedUser && data.selectedUser.length > 0) {
      query = { ...query, assignTo: { ["$in"]: data.selectedUser } };
    }

    if (data.selectedDisposition && data.selectedDisposition.length > 0) {
      query = { ...query, disposition: { ["$in"]: data.selectedDisposition } };
    }

    if (user && ["field_exc", "agent"].indexOf(user.role) > -1) {
      query = { ...query, assignTo: user && user.username };
    }

    if(data.mobileNo && data.mobileNo.length > 0){
      query = {...query, phone: data.mobileNo};
    }

    if (data.startDate && data.endDate) {
      query = {
        ...query,
        callback: {
          $gte: new Date(data.startDate),
          $lt: new Date(data.endDate),
        },
      };
    }

    query = { ...query, admin: user && user.admin };
    return query;
  };

  return (
    <>
      <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => setNotify({ open: false })}
        close
      />


        {/* <GridItem xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center">
          <Typography variant="body1" style={{ marginLeft: "10px", color: "red", display: "flex", alignItems: "center",  paddingTop:"20px", marginBottom: "-20px" }}>
            <InfoIcon color="error" fontSize="small" style={{ marginRight: "5px" }} /> 
            <a href="#" target="_blank" style={{color:"red", fontSize:"13px"}}>Information</a>
          </Typography>
        </GridItem> */}
      <Card>
        <CardHeader color="transparent">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h3>
                Overdue
                <span style={{ float: "right", fontSize: "15px" }}>
                  <GridItem  style={{float: "left", marginTop: "10px"}}>
                      <InfoBox name={'Schedule.Overdue'} />
                  </GridItem>
                  <Button
                    size="sm"
                    title="Filter"
                    color="info"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    <i className="fa fa-filter" /> &nbsp; Filter
                  </Button>

                  {/* <Button
                    size="sm"
                    title="Filter"
                    color="info"
                    onClick={() => {
                      setIsCallBackChange(true);
                    }}
                  >
                    <i className="fa fa-save" /> &nbsp; Callback
                  </Button> */}
                  {["manager", "owner"].indexOf(user && user.role) > -1 && (
                    <Button
                      size="sm"
                      color="transparent"
                      justIcon
                      title="Export To Excel"
                      onClick={() => {
                        handleExportExcel();
                      }}
                    >
                      <GetAppOutlinedIcon />
                    </Button>
                  )}
                </span>
              </h3>
            </GridItem>




            {open && (
              <Filters
                open={open}
                handleClose={() => {
                  setOpen(false);
                }}
                ShowCampaign={true}
                filterData={filterData}
                user={user}
              />
            )}
          </GridContainer>
        </CardHeader>

        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {
              data.activityData && (
            <DataTable
              checkboxes={true}
              selectableRows={true}
              columns={columns}
              data={data.activityData || []}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={data.total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              onChangePage={handleChangePage}
              onSelectedRowsChange={handleCheckBox}
            />
              )
            }

          </GridItem>
        </GridContainer>
      </Card>
    </>
  );
}

export default Overdue;
