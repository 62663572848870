import React, { useRef, useEffect, useState } from 'react';
import { render } from 'react-dom'


import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import { API_GET, API_POST } from "../../services/api.service";
import { copyToClipboard } from "../../services/Common";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Divider } from '@material-ui/core';


const SMSSetup = (props) => {

    const { classes, user, cid } = props;


    const TextRef = useRef(null);
    const customFields = [
        { 'display_Name': 'phone', 'field_name': 'phone' },
        { 'display_Name': 'Message', 'field_name': 'message' },
        // { 'display_Name': 'Agent Mobile', 'field_name': 'agentMobile' },
        // { 'display_Name': 'Call Type', 'field_name': 'callType' },
        // { 'display_Name': 'IsWebPhone', 'field_name': 'IsWebPhone' },
        // { 'display_Name': 'CallTime', 'field_name': 'CallTime' },
        // { 'display_Name': 'Duration', 'field_name': 'Duration' },
        // { 'display_Name': 'Customer BillSec', 'field_name': 'Customer_BillSec' },
        // { 'display_Name': 'Agent BillSec', 'field_name': 'Agent_BillSec' },
        // { 'display_Name': 'UniqueId', 'field_name': 'UniqueId' },
        // { 'display_Name': 'Status', 'field_name': 'Status' },
        // { 'display_Name': 'Type', 'field_name': 'Type' }

    ];



    let frmVal = {
        api: null,
        name: null,
        admin: user.admin,
        IsActive: true,
        type: 'SMSAPI',
        createdBy: user.username,
        createdOn: new Date()
    };
    const [formValues, setFormValues] = useState(frmVal);
    const [textValue, settextValue] = useState(frmVal.api);
    const [count, setCount] = useState(0);
    const [apiAppendPoint, setapiAppendPoint] = useState(0);

    const save = async () => {
        const tid = props.match.params.id;

        if (user && user.admin) {

            let frmValues = {
                ...formValues
            }

            if (tid) {
                delete frmValues["_id"];
                let registerRequest = await API_POST("action/update", {
                    root: "CallingSetup",
                    body: frmValues,
                    querydata: { _id: tid },
                });
            }
            else {
                let registerRequest = await API_POST("action/addRecord", {
                    root: "CallingSetup",
                    body: frmValues,
                });

                console.log(registerRequest);
            }


            props.history.push("/admin/SMSSetupList");
        }

    };

    const copyTo = (e, item) => {
        console.log(e);

        //console.log(TextRef);

        var caretPos = apiAppendPoint;
        var textAreaTxt = textValue;

        var txtToAdd = '${' + item.field_name + '}';

        if (textAreaTxt) {
            let updatedtext = textAreaTxt.substring(0, caretPos) + txtToAdd + textAreaTxt.substring(caretPos);
            let formValue = formValues;
            formValue['api'] = updatedtext;
            setFormValues(formValue);
            setCount(count + 1);
        }

        //let str = e.target.innerHTML
        copyToClipboard('${' + item.field_name + '}', e.target);
        //alert(str);
    };

    const onLoad = () => {
        try {
            //console.log("e", e)
            // you can load your template here;
            debugger;
            const tid = props.match.params.id;
            if (tid && formValues && formValues.design) {
                const templateJson = formValues && formValues.design;

            }


        }
        catch (err) {
            console.log(err);
        }
    };





    useEffect(() => {
        if (user) {
            const tid = props.match.params.id;
            if (tid) {
                loadTemplate(tid);
            }
        }
    }, [user]);

    const loadTemplate = async (tid) => {
        let registerRequest = await API_POST("action/getdata", {
            root: "CallingSetup",
            con: { admin: user && user.admin, _id: tid },
            cols: {},
        });

        if (registerRequest && registerRequest.status == 200) {
            setFormValues(registerRequest.data[0]);
            console.log(registerRequest.data[0]);



        }

        //setFormValues(formValue);
    }
    const handleChange = async (e) => {
        let formValue = formValues;


        if (e.target && e.target.type === "checkbox") {
            formValue[e.target.id] = e.target.checked;
        } else if (e._isAMomentObject) {
            formValue[props] = e.format();
        } else {
            formValue[e.target.name] = e.target.value === "" ? null : e.target.value;
        }

        setFormValues(formValue);



        if (e.target && e.target.type === "textarea") {
            settextValue(formValue.api);
            setapiAppendPoint(e.target.selectionStart);
            //   alert(e.target.selectionStart)
        }
        setCount(count + 1);
    };

    useEffect(() => { }, [count]);



    return (
        <GridContainer>
            <Snackbar
                place="tr"
                // color={this.state.notify.color}
                // message={this.state.notify.message}
                // open={this.state.notify.open}
                // closeNotification={() =>
                //   this.setState({
                //     notify: {
                //       open: false,
                //     },
                //   })
                // }
                close
            />
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="transparent">
                        <h3>Configuration
                        </h3>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={8}>
                                <div className="editor">

                                    <GenerateField
                                        value={
                                            formValues && formValues.api
                                        }
                                        onChange={handleChange}
                                        id={"api"}
                                        label={"API"}
                                        type="textarea"
                                        ref={TextRef}

                                        required={"true"}
                                        name={"api"}

                                        rows={20}
                                        rowsMax={30}
                                    />

                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <GridItem xs={12} sm={12} md={12}>

                                    <CustomInput
                                        labelText="Name *"
                                        id="name"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        labelProps={{ shrink: true }}
                                        inputProps={{
                                            required: true,
                                            value: formValues && formValues.name,
                                            name: "name",
                                            onChange: handleChange,
                                        }}
                                    />
                                </GridItem>

                                <GenerateField
                                    value={formValues && formValues.IsActive}
                                    onChange={handleChange}
                                    id={"IsActive"}
                                    label={"Active"}
                                    type="checkbox"
                                />
                                <GridItem xs={12} sm={12} md={12}>
                                    <Button
                                        variant="transparent"
                                        color="info"
                                        size="sm"

                                        onClick={save}
                                    >
                                        Save
                                    </Button>
                                </GridItem>

                                <Divider />

                                <GridItem xs={12} sm={12} md={12} style={{ height: '400px', overflow: 'auto' }}>
                                    {
                                        customFields.map((item, index) => {
                                            return <Button
                                                variant="transparent"
                                                size="sm"
                                                onClick={(e) => copyTo(e,item)}
                                            >
                                                {item.display_Name}
                                            </Button>
                                        })
                                    }
                                </GridItem>
                            </GridItem>


                        </GridContainer>



                    </CardBody>
                    <CardFooter>

                    </CardFooter>
                </Card>
            </GridItem>


        </GridContainer>



    );
};
export default withStyles(dashboardStyle)(SMSSetup);
class GenerateField extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            value,
            onChange,
            id,
            options,
            label,
            keyValuePair,
            type = "select",
            disabled = false,
        } = this.props;

        return (
            <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                    labelText={label}
                    id
                    labelProps={{ shrink: true }}
                    formControlProps={{
                        fullWidth: true,
                        multiline: true,
                    }}
                    customOptions={options}
                    inputProps={{
                        required: false,
                        value,
                        defaultValue: value,
                        checked: value,
                        name: id,
                        id: id,
                        type: type,
                        onChange,
                        disabled,
                        options: keyValuePair,
                    }}
                />
            </GridItem>
        );
    }
}
