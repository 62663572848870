/* eslint-disable */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";

import { API_GET, API_POST } from "../../services/api.service";
import { CardActions } from "@material-ui/core";

import Loader from "components/Loader/Loader.jsx";
import InfoBox from "../../components/InfoIcon/InfoIcon";


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

class ManageCampaigns extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props;
    this.state = {
      campaignsDetails: null,
      customFormFields: [],
      dispositions: [],
      telephony: [],
      showLoader: true,
      _id: null,
      templates: [],
      mode: "Add",
      notify: {
        open: false,
        message: "",
        color: "success",
      },
    };
    const defaultData = {
      name: "",
      workflow: "",
      telephony: "Default",
      viewType: "",
      prospectFilter: "",
      helpScript: "",
      launchTime: "",
      disposition: [],
      formFields: [],
      callImmediately: false,
      mandatoryRemarks: false,
      enableAgentFilter: false,
      phoneNumberMasking: false,
      assignFollowUps: "",
      scheduleAppointment: false,
      minimumSlotDuration: 0,
      enableMessageOnDisposition: false,
      enableEditMessage: false,
      enableDeleteProspect: false,
      ActiveInactiveProspect: false,
      IsActive: false,
      communication_type: null,
      templateId: null,
      enable_message: false,
      createdOn: null,
      updatedOn: null,
      admin: user && user.admin,
    };

    this.state.campaignsDetails = defaultData;
  }
  // to stop the warning of calling setState of unmounted component
  componentDidMount() {
    const { user } = this.props;
    let id = this.props.match.params.id;
    if (
      id &&
      this.props.match.url.indexOf("/admin/editcampaigns") > -1 &&
      user
    ) {
      this.setState({ _id: id, mode: "Edit" });
      this.loadCampaign(id);
    } else {
      this.setState({ showLoader: false });
    }
    //debugger;
    //this.setState({ campaignsDetails: this.defaultData });
    if (user) {
      this.loadCustomFields();
      this.loadDispositions();
      this.localCallingSetUp();
    }
  }
  showNotification(place) { }


  getTemplate = async (type) => {
    const { user } = this.props;
    if (type == "none") {
      return;
    }
    try {
      var data = await API_POST("action/getdata", {
        root: "Templates",
        con: { admin: user && user.admin, isActive: true, type: type },
        cols: {},
      });

      if (data && data.status == 200) {
        let returnVal = [];
        if (data && data.data.length > 0) {

          for (let index = 0; index < data.data.length; index++) {
            let element = data.data[index];
            element["key"] = element._id;
            element["value"] = element.name;
            returnVal.push(element);

          }

        }
        this.setState({ templates: returnVal });


      }

    } catch (error) {
      console.log(error);

      //registerRequest = response;
    }
  }

  handleChange = async (e) => {
    debugger;
    let formValue = this.state.campaignsDetails;

    if (e.target && e.target.type === "checkbox") {
      formValue[e.target.id] = e.target.checked;
    } else if (e._isAMomentObject) {
      formValue[props] = e.format();
    } else {
      formValue[e.target.name] = e.target.value === "" ? null : e.target.value;
    }
    if (e.target.name == 'communication_type') {

      this.getTemplate(e.target.value);
    }

    this.setState({ campaignsDetails: formValue });
  };

  loadCustomFields = async (e) => {
    const { user } = this.props;
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "custom_forms_new",
        con: { admin: user && user.admin },
        cols: {},
      });

      if (registerRequest && registerRequest.status == 200) {
        if (registerRequest && registerRequest.data) {
          let _options = [];
          registerRequest.data.forEach((element) => {
            _options.push({
              key: element._id,
              value: element.custom_field_name,
            });
          });
          this.setState({ customFormFields: _options });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  loadDispositions = async (e) => {
    const { user } = this.props;
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "dispositions",
        con: { admin: user && user.admin,IsActive:{$ne: false} },
        cols: {},
      });

      if (registerRequest && registerRequest.status == 200) {
        let _options = [];
        registerRequest.data.forEach((element) => {
          if (element.parentId === null)
            _options.push({
              key: element._id,
              value: element.disposition_value,
            });
        });

        this.setState({ dispositions: _options });
      }
    } catch (error) {
      console.log(error);
    }
  };

  loadCampaign = async (id) => {
    const { user } = this.props;

    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "campaigns",
        con: { _id: id, admin: user && user.admin },
        cols: {},
      });

      if (registerRequest && registerRequest.status == 200) {
        //console.log(registerRequest.data);
        if (registerRequest.data && registerRequest.data.length > 0) {
          this.getTemplate(registerRequest.data[0].communication_type);
          this.setState({
            campaignsDetails: registerRequest.data[0],
            showLoader: false,
          });
        }
        //this.setState({ campaignsDetails: registerRequest.data });
      }
    } catch (error) {
      console.log(error);
    }
  };
  handleCancel = async (e) => {
    const { user, history } = this.props;
    history.push("/admin/campaigns");
  };

  handleSaveCampaign = async (e) => {
    if (this.state.mode === "Edit") {
      this.handleEditCampaign(e);
    } else {
      this.handleAddCampaign(e);
    }
  };

  handleAddCampaign = async (e) => {
    console.log(this.state.campaignsDetails);

    const { user, history } = this.props;
    let formValue = this.state.campaignsDetails;
    formValue["admin"] = user && user.admin;
    formValue["createdBy"] = user && user.username;
    formValue["createdOn"] = new Date();
    if(["manager"].indexOf(user && user.role) > -1){
    formValue['campaignMembers']=[user && user.username];
    }
    let registerRequest;
    try {


      let registerRequest = await API_POST("action/getdata", {
        root: "campaigns",
        con: { admin: user && user.admin, name: formValue.name },
        cols: {},
      });

      if (registerRequest && registerRequest.status == 200) {
        if (registerRequest.data.length > 0) {
          this.setState({
            notify: {
              open: true,
              message: "Duplicate Campaign Name Found",
              color: "danger",
            },
          });
          return true;
        }
      }

      registerRequest = await API_POST("action/addRecord", {
        root: "campaigns",
        body: formValue,
      });



      if(["manager"].indexOf(user && user.role) > -1){

         await API_POST("action/upsert", {
          root: "campaignMembers",
          body: {
            role: user.role,
            createdOn: new Date(),
            createdBy: user.username,
            updatedOn: new Date(),
            updatedBy: user.username,
            IsActive: true,
          },
          querydata: { cid: registerRequest.data, username: user.username  ,admin: user.admin},
        });


      }

      if (registerRequest && registerRequest.status == 200) {
        history.push("/admin/campaigns");
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleEditCampaign = async (e) => {
    console.log(this.state.campaignsDetails);

    const { user, history } = this.props;
    let formValue = this.state.campaignsDetails;
    let _id = this.state._id;
    formValue["admin"] = user && user.admin;
    formValue["updatedOn"] = new Date();
    delete formValue["_id"];

    let registerRequest;
    try {
      registerRequest = await API_POST("action/update", {
        root: "campaigns",
        body: formValue,
        querydata: { _id },
      });

      if (registerRequest && registerRequest.status == 200) {
        history.push("/admin/campaigns");
      }
    } catch (error) {
      console.log(error);
    }
  };

  localCallingSetUp = async (e) => {
    debugger;
    const { user } = this.props;
    let registerRequest;
    try {


      registerRequest = await API_POST("action/getdata", {
        root: "CallingSetup",
        con: { admin: user && user.admin, IsActive: true, type: 'CallingAPI' },
        cols: {},
      });

      if (registerRequest && registerRequest.status == 200) {
        let _options = [{
          key: "Default",
          value: "Default",
        }];
        registerRequest.data.forEach((element) => {
          _options.push({
            key: element._id,
            value: element.name,
          });
        });

        this.setState({ telephony: _options });
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { classes, user } = this.props;
    const {
      campaignsDetails,
      dispositions,
      telephony,
      customFormFields,
      mode,
      showLoader,
      templates
    } = this.state;

    return (
      <>
      <Card>

        <Snackbar
          place="tr"
          color={this.state.notify.color}
          message={this.state.notify.message}
          open={this.state.notify.open}
          closeNotification={() =>
            this.setState({
              notify: {
                open: false,
              },
            })
          }
          close
        />

        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{mode} Campaigns</h4>
          <p className={classes.cardCategoryWhite}>Manage your campaigns</p>
        </CardHeader>
        <CardBody>
          {showLoader ? <Loader /> : null}
          {showLoader ? null : (
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <>
                  <>
                    <h3>Campaign Details
                    <GridItem  style={{float: "right", marginTop: "10px" }}>
                        <InfoBox name={'Campaigns.AddCampaigns'} />
                    </GridItem>
                    </h3>
                  </>
                  <>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Name *"
                          id="name"
                          labelProps={{ shrink: true }}
                          formControlProps={{
                            fullWidth: true,
                            multiline: true,
                          }}
                          inputProps={{
                            required: true,
                            value: campaignsDetails && campaignsDetails.name,
                            name: "name",
                            onChange: this.handleChange,
                          }}
                        />
                      </GridItem>

                      <GenerateField
                        value={campaignsDetails && campaignsDetails.workflow}
                        onChange={this.handleChange}
                        id={"workflow"}
                        label={"Work Flow"}
                        options={"None,Lead Generation,Custom"}
                      />
                      <GenerateField
                        value={campaignsDetails && campaignsDetails.telephony}
                        onChange={this.handleChange}
                        id={"telephony"}
                        label={"Telephony"}
                        keyValuePair={telephony}
                      />

                      <GenerateField
                        value={campaignsDetails && campaignsDetails.viewType}
                        onChange={this.handleChange}
                        id={"viewType"}
                        label={"View Type"}
                        options={"None,Cycle Based,Sheet Based"}
                      />
                      <GenerateField
                        value={
                          campaignsDetails && campaignsDetails.prospectFilter
                        }
                        onChange={this.handleChange}
                        id={"prospectFilter"}
                        label={"Prospect Filter"}
                        options={"None"}
                      />
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Help Script"
                          id="helpScript"
                          labelProps={{ shrink: true }}
                          formControlProps={{
                            fullWidth: true,
                            multiline: true,
                          }}
                          inputProps={{
                            required: false,
                            value:
                              campaignsDetails && campaignsDetails.helpScript,
                            name: "helpScript",
                            type: "textarea",
                            onChange: this.handleChange,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Launch Time"
                          id="launchTime"
                          labelProps={{ shrink: true }}
                          formControlProps={{
                            fullWidth: true,
                            multiline: true,
                          }}
                          inputProps={{
                            required: false,
                            value:
                              campaignsDetails && campaignsDetails.launchTime,
                            defaultValue:
                              campaignsDetails && campaignsDetails.launchTime,
                            name: "launchTime",
                            type: "date_time",
                            onChange: this.handleChange,
                          }}
                        />
                      </GridItem>


                      <>
                        <GridItem xs={12} sm={12} md={12} pt={20}>

                          <GenerateField
                            value={
                              campaignsDetails && campaignsDetails.enable_message
                            }
                            onChange={this.handleChange}
                            id={"enable_message"}
                            label={"Enable Welcome Message on new Integration Prospect"}
                            type="checkbox"
                          />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12} pt={20}>

                            <GenerateField
                              value={
                                campaignsDetails && campaignsDetails.enable_template_whatsapp == false ? false : true
                              }
                              onChange={this.handleChange}
                              id={"enable_template_whatsapp"}
                              label={"Enable Template Option(whatsapp)"}
                              type="checkbox"
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12} pt={20}>

                            <GenerateField
                              value={
                                campaignsDetails && campaignsDetails.enable_template_email == false ? false : true
                              }
                              onChange={this.handleChange}
                              id={"enable_template_email"}
                              label={"Enable Template Option(email)"}
                              type="checkbox"
                            />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12} pt={20}>

                            <GenerateField
                              value={
                                campaignsDetails && campaignsDetails.enable_template_sms == false ? false : true
                              }
                              onChange={this.handleChange}
                              id={"enable_template_sms"}
                              label={"Enable Template Option(sms)"}
                              type="checkbox"
                            />
                            </GridItem>


                        {campaignsDetails && campaignsDetails.enable_message ?
                          (<>

                            <GridItem xs={12} sm={12} md={12}>
                              <CustomInput
                                labelText="Select Communication Type"
                                id="communication_type"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                labelProps={{ shrink: true }}
                                inputProps={{
                                  required: false,
                                  name: "communication_type",
                                  type: "select",
                                  value: campaignsDetails && campaignsDetails.communication_type,
                                  onChange: this.handleChange,
                                  options: [
                                    {
                                      key: "none",
                                      value: "No Template"
                                    },
                                    {
                                      key: "email",
                                      value: "Email Template"
                                    },
                                    {
                                      key: "sms",
                                      value: "SMS Template"
                                    },
                                    {
                                      key: "whatsapp",
                                      value: "WhatsApp Template"
                                    }
                                  ],
                                }}
                              />
                            </GridItem>

                            {campaignsDetails && (['', 'none'].indexOf(campaignsDetails.templateId) == -1) &&
                              <GridItem xs={12} sm={12} md={12}>
                                <CustomInput
                                  labelText="Select Template Type"
                                  id="templateId"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  labelProps={{ shrink: true }}
                                  inputProps={{
                                    required: false,
                                    name: "templateId",
                                    type: "select",
                                    defaultValue: campaignsDetails && campaignsDetails.templateId,
                                    onChange: this.handleChange,
                                    options: templates,
                                  }}
                                />
                              </GridItem>
                            }

                          </>
                          ) : null
                        }
                      </>
                    </GridContainer>
                  </>
                </>
              </GridItem>

              <GridItem xs={12} sm={6} md={6}>
                <>
                  <>
                    <h3>Campaign Settings</h3>
                  </>
                  <>
                    <GridContainer>
                      <GenerateField
                        value={campaignsDetails && campaignsDetails.disposition}
                        onChange={this.handleChange}
                        id={"disposition"}
                        label={"Disposition"}
                        type="checkbox_list"
                        keyValuePair={dispositions}
                      />
                      <GenerateField
                        value={campaignsDetails && campaignsDetails.formFields}
                        onChange={this.handleChange}
                        id={"formFields"}
                        label={"Additional Fields"}
                        type="checkbox_list"
                        keyValuePair={customFormFields}
                      />
                      <GenerateField
                        value={
                          campaignsDetails && campaignsDetails.callImmediately
                        }
                        onChange={this.handleChange}
                        id={"callImmediately"}
                        label={
                          "Call Prospect immediately after adding manually"
                        }
                        type="checkbox"
                      />

                      <GenerateField
                        value={
                          campaignsDetails && campaignsDetails.mandatoryRemarks
                        }
                        onChange={this.handleChange}
                        id={"mandatoryRemarks"}
                        label={"Require remarks in response"}
                        type="checkbox"
                      />
                      <GenerateField
                        value={
                          campaignsDetails && campaignsDetails.enableAgentFilter
                        }
                        onChange={this.handleChange}
                        id={"enableAgentFilter"}
                        label={"Enable Agent filters while calling"}
                        type="checkbox"
                      />
                      <GenerateField
                        value={
                          campaignsDetails &&
                          campaignsDetails.phoneNumberMasking
                        }
                        onChange={this.handleChange}
                        id={"phoneNumberMasking"}
                        label={"Mask Phone numbers"}
                        type="checkbox"
                      />

                      <GenerateField
                        value={
                          campaignsDetails && campaignsDetails.assignFollowUps
                        }
                        onChange={this.handleChange}
                        id={"assignFollowUps"}
                        label={"Assign Follow-Ups To"}
                        type="select"
                        keyValuePair={[
                          {
                            key: "None",
                            value: "None",
                          },
                          {
                            key: "Any",
                            value: "Any available agent",
                          },
                          {
                            key: "Past",
                            value: "One of Past Handler",
                          },
                          {
                            key: "Last",
                            value: "Only Last Handler",
                          },
                        ]}
                      />

                      <GenerateField
                        value={
                          campaignsDetails &&
                          campaignsDetails.scheduleAppointment
                        }
                        onChange={this.handleChange}
                        id={"scheduleAppointment"}
                        label={"Schedule appointments in slots"}
                        type="checkbox"
                      />

                      <GenerateField
                        value={
                          campaignsDetails &&
                          campaignsDetails.minimumSlotDuration
                        }
                        onChange={this.handleChange}
                        id={"minimumSlotDuration"}
                        label={"Minimum slot duration"}
                        type="text"
                        disabled={!campaignsDetails.scheduleAppointment}
                      />

                      <GenerateField
                        value={
                          campaignsDetails &&
                          campaignsDetails.enableMessageOnDisposition
                        }
                        onChange={this.handleChange}
                        id={"enableMessageOnDisposition"}
                        label={"Send Messages based on Disposition"}
                        type="checkbox"
                      />
                      <GenerateField
                        value={
                          campaignsDetails && campaignsDetails.enableEditMessage
                        }
                        onChange={this.handleChange}
                        id={"enableEditMessage"}
                        label={"Allow to Edit Messages"}
                        type="checkbox"
                      />
                      <GenerateField
                        value={
                          campaignsDetails && campaignsDetails.enableFreshProspect
                        }
                        onChange={this.handleChange}
                        id={"enableFreshProspect"}
                        label={"Allow to Make Prospect Fresh"}
                        type="checkbox"
                      />
                      <GenerateField
                        value={
                          campaignsDetails &&
                          campaignsDetails.enableDeleteProspect
                        }
                        onChange={this.handleChange}
                        id={"enableDeleteProspect"}
                        label={"Allow to Delete Prospect"}
                        type="checkbox"
                      />

                      <GenerateField
                        value={
                          campaignsDetails &&
                          campaignsDetails.enableAssignFromCallPage
                        }
                        onChange={this.handleChange}
                        id={"enableAssignFromCallPage"}
                        label={"Allow to Assign Prospect from Call Page"}
                        type="checkbox"
                      />

                      <GenerateField
                        value={
                          campaignsDetails &&
                          campaignsDetails.ActiveInactiveProspect
                        }
                        onChange={this.handleChange}
                        id={"ActiveInactiveProspect"}
                        label={"Allow to Active/Deactive Files"}
                        type="checkbox"
                      />

                      <GenerateField
                        value={
                          campaignsDetails &&
                          campaignsDetails.closeTagsPermission == false ? false : true
                        }
                        onChange={this.handleChange}
                        id={"closeTagsPermission"}
                        label={"Permission to remove tags to agents"}
                        type="checkbox"
                      />

                      <GenerateField
                        value={
                          campaignsDetails &&
                          campaignsDetails.disableManualProspectCreate
                        }
                        onChange={this.handleChange}
                        id={"disableManualProspectCreate"}
                        label={"Disable Manual Prospect Creation"}
                        type="checkbox"
                      />


                  <GenerateField
                        value={
                          campaignsDetails &&
                          campaignsDetails.requiredDisposition
                        }
                        onChange={this.handleChange}
                        id={"requiredDisposition"}
                        label={"Require atleast 1 tags to save dispositions"}
                        type="checkbox"
                      />

                   


                      <GenerateField
                        value={
                          campaignsDetails &&
                          campaignsDetails.enableAutoCalling
                        }
                        onChange={this.handleChange}
                        id={"enableAutoCalling"}
                        label={"Allow to Auto Call"}
                        type="checkbox"
                      />

                      <GenerateField
                        value={
                          campaignsDetails && campaignsDetails.autoCallDuration
                        }
                        onChange={this.handleChange}
                        id={"autoCallDuration"}
                        label={"Auto Call Duration ( in Sec )"}
                        disabled={!campaignsDetails.enableAutoCalling}
                        type="select"
                        keyValuePair={[
                          {
                            key: "5",
                            value: "5",
                          },
                          {
                            key: "10",
                            value: "10",
                          },
                          {
                            key: "15",
                            value: "15",
                          },
                          {
                            key: "20",
                            value: "20",
                          },
                          {
                            key: "25",
                            value: "25",
                          },
                          {
                            key: "30",
                            value: "30",
                          },
                        ]}
                      />

                      <GenerateField
                        value={campaignsDetails && campaignsDetails.IsActive}
                        onChange={this.handleChange}
                        id={"IsActive"}
                        label={"Active"}
                        type="checkbox"
                      />

                    </GridContainer>
                  </>
                </>
              </GridItem>
            </GridContainer>
          )}
        </CardBody>
        {showLoader ? null : (
          <CardActions>

            <Button color="primary" size="sm" onClick={this.handleCancel}>
              Cancel
            </Button>
            <Button color="primary" size="sm" onClick={this.handleSaveCampaign}>
              Save
            </Button>
          </CardActions>
        )}
      </Card>
      </>

    );
  }
}

export default withStyles(styles)(ManageCampaigns);

class GenerateField extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      value,
      onChange,
      id,
      options,
      label,
      keyValuePair,
      type = "select",
      disabled = false,
    } = this.props;

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CustomInput
          labelText={label}
          id
          labelProps={{ shrink: true }}
          formControlProps={{
            fullWidth: true,
            multiline: true,
          }}
          customOptions={options}
          inputProps={{
            required: false,
            value,
            defaultValue: value,
            checked: value,
            name: id,
            id: id,
            type: type,
            onChange,
            disabled,
            options: keyValuePair,
          }}
        />
      </GridItem>
    );
  }
}
