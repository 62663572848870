import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import TextField from "@material-ui/core/TextField";
import { API_POST } from "../../services/api.service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress"; // Import CircularProgress

import Chip from "@material-ui/core/Chip";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import DialogTitle from "../../components/DialogTitle/DialogTitle";
import CustomInput from "components/CustomInput/CustomInput.jsx";

const style = {
  disposition: {
    display: "inline-block",
    width: "100%",
  },
  dispositionActions: {
    float: "right",
  },
};

function ProspectFresh(props) {
  debugger;
  const { cid, IsAssignment, allProspects, selectedRows, onChange, user } = props;

  const [Notify, setNotify] = useState({
    open: false,
    message: "",
    color: "success",
  });
  const [allUsers, setAllUsers] = useState([]);
  const [removeAssignTo, setRemoveAssignTo] = useState(false);
  const [removeHistory, setRemoveHistory] = useState(false);
  const [removeSchedules, setRemoveShedules] = useState(false);
  let [InputType, setInputType] = useState("");
  const [loading, setLoading] = useState(false); // Add loader state


  const getCampaignUsers = async () => {
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "campaignMembers",
        //Change according to roleid --only visible agent and field executive
        //con: { role: user && user.role ,cid:cid },
        con: { cid: cid },
      });

      if (registerRequest && registerRequest.status == 200) {
        setAllUsers(registerRequest && registerRequest.data);
      }
    } catch (error) { }
  };

  const handleSetAssignment = async (Type, InputType, selectedProspects) => {
    debugger;
    if (Type != "selectedAll" && selectedProspects.length == 0) {
      alert("Please select Prospects");
      return;
    }

    let Isupdated = true;
    Isupdated = true;

    console.log(Type, Isupdated)

    if (Type === "selectedAll" && Isupdated == true) {
      let result = window.confirm("Do you want to update all prospects");
      Isupdated = result ? result : false;
      if (Isupdated) {
        setLoading(true); // Start loader
        await updateAssignment(InputType, props.Query, props.Query);
        setLoading(false); // Stop loader
      }
      // InsertAssignmentHistory(InputType, props.Query);
    } else {
      Isupdated = true;
      setLoading(true); // Start loader
      for (let row of selectedProspects) {
        let query = { _id: row._id };
        let query2 = { pid: row._id };

        await updateAssignment(InputType, query, query2);
      }


      Isupdated && setNotify({
        open: true,
        message: "Fresh successfully",
        color: "success",
      });

      if (onChange) {
        onChange();
      }

      setTimeout(function () {
        props.handleClose();
        props.RemoveAllChecks(false);
        setLoading(false); // Stop loader
      }, 3000);
    }
  };

  const updateAssignment = async (User, query, query2) => {
    let registerRequest;

    query.removeAssignTo = removeAssignTo;
    query.removeHistory = removeHistory;
    query.removeSchedules = removeSchedules;

    registerRequest = await API_POST("action/makeFresh", {
      querydata: query,
    });

    if (registerRequest && registerRequest.status == 200) {
      console.log("Change successfully");
      setNotify({
        open: true,
        message: "prospect changed to fresh",
        color: "success",
      });
    }
  };

  useEffect(() => {
    getCampaignUsers();
  }, []);

  return (
    <>
      <Snackbar
        place="tr"
        color={Notify.color}
        message={Notify.message}
        open={Notify.open}
        autoHideDuration={3000}
        closeNotification={() => setNotify({ open: false })}
        close
      />



      <GridContainer>
        <Dialog open={IsAssignment} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth={true}>
          <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
            Make Prospects as new
          </DialogTitle>

          <DialogActions>
            <GridContainer>

            <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText={"Remove AssignTo"}
                        id={"removeAsignTo"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange:(e) => {
                            setRemoveAssignTo(e.target.checked)
                          },
                          name: "removeAsignTo",
                          value:{removeAssignTo},
                          type:  "checkbox",
                          defaultValue:{removeAssignTo},
                        }}
                      />

            </GridItem>


            <GridItem xs={12} sm={12} md={12}  container spacing={2} justifyContent="space-between">
            <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText={"Remove Activity and Shedule"}
                        id={"removeActivity"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange:(e) => {
                            setRemoveHistory(e.target.checked)
                          },
                          name: "removeActivity",
                          value:{removeHistory},
                          type:  "checkbox",
                          defaultValue:{removeHistory},
                        }}
                      />
                      </GridItem>




            </GridItem>

            <GridItem xs={12} sm={12} md={12}>


            </GridItem>



              <GridItem xs={12} sm={12} md={12}>
                <Button
                  size="sm"
                  disabled={loading || selectedRows.length == 0}
                  onClick={() => {
                    handleSetAssignment("selected", InputType, selectedRows);
                  }}
                  color="primary"
                >
                  Selected - {selectedRows.length > 0 ? selectedRows.length : 0}
                </Button>
                <Button
                  size="sm"
                  disabled={loading}
                  onClick={() => {
                    handleSetAssignment("selectedAll", InputType, allProspects);
                  }}
                  color="primary"
                >
                  Selected All - {props.totalRowCount ? props.totalRowCount : 0}
                </Button>
                {loading && ( // Render loader when loading is true
                  <div style={{ display: "inline", padding: "5px" }}>
                    <CircularProgress style={{ width: '24px', height: '24px', verticalAlign: 'middle' }} />
                  </div>
                )}
                {/* <Button onClick={props.handleClose} color="primary">
                  Close
                </Button> */}
              </GridItem>
            </GridContainer>
          </DialogActions>
        </Dialog>
      </GridContainer>
    </>
  );
}

ProspectFresh.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(ProspectFresh);
