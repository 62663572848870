import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

const JsonBinder = ({ json }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Key</TableCell>
          <TableCell>Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(json).map((key, index) => (
          <TableRow key={index}>
            <TableCell>{key}</TableCell>
            <TableCell>{json[key]}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default JsonBinder;