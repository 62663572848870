import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Card from "../../../../../components/Card/Card.jsx";
import CardHeader from "../../../../../components/Card/CardHeader.jsx";
import CardBody from "../../../../../components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { API_POST } from "services/api.service";
import DataTable from "react-data-table-component";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  filterContainer: {
    marginBottom: theme.spacing(2)
  }
}));

const EmailLogs = ({ user }) => {
  const classes = useStyles();
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: "Template Name",
      selector: row => row.templateName,
      sortable: true
    },
    {
      name: "Recipient",
      selector: row => row.recipient,
      sortable: true
    },
    {
      name: "Status",
      selector: row => row.status,
      sortable: true
    },
    {
      name: "Sent At",
      selector: row => new Date(row.sentAt).toLocaleString(),
      sortable: true
    }
  ];

  useEffect(() => {
    fetchLogs();
  }, []);

  const fetchLogs = async () => {
    setLoading(true);
    try {
      const response = await API_POST("email/logs/list", {
        admin: user.admin
      });
      if (response.status) {
        setLogs(response.data || []);
      }
    } catch (error) {
      console.error("Error fetching email logs:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <h4>Email Logs (Comming Soon)</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12}>
            <DataTable
              columns={columns}
              data={logs}
              pagination
              progressPending={loading}
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              highlightOnHover
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

EmailLogs.propTypes = {
  user: PropTypes.object.isRequired
};

export default EmailLogs;
